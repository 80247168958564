import React, { Component } from "react";
import ls from "local-storage";
import withUnmounted from '@ishawnwang/withunmounted';
import queryString from 'query-string';
ls.backend(sessionStorage);

class PaymentSuccess extends Component {

  async componentWillMount(){
      window.parent.paypalResopnse = queryString.parse(this.props.location.search);
      window.close();
  }

  render(){
      return(
          <React.Fragment>
            <div className="center-container">
            <div className="error">
                  <div className="container-floud">
                      <div className="col-xs-12 ground-color text-center">
                          <div className="container-error-404">
                             
                          </div>
                          <h2 className="h1">We are processing your payment</h2>
                      </div>
                  </div>
              </div>
            </div>
          </React.Fragment>
      );
  }
}

export default withUnmounted(PaymentSuccess);