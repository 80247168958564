import React, { Component } from 'react';
import '../css/mysqpaymentform.css';
import Checkout from "../classes/checkout";
import squareImg from "../images/square.png";
import LaddaButton, { L, SLIDE_UP } from "react-ladda";
import ls from "local-storage";
ls.backend(sessionStorage);

const styles = {
  name: {
    verticalAlign: 'top',
    display: 'none',
    margin: 0,
    border: 'none',
    fontSize: "16px",
    fontFamily: "Helvetica Neue",
    padding: "16px",
    color: "#373F4A",
    backgroundColor: "transparent",
    lineHeight: "1.15em",
    placeholderColor: "#000",
    _webkitFontSmoothing: "antialiased",
    _mozOsxFontSmoothing: "grayscale",
  },
  leftCenter: {
    float: 'left',
    textAlign: 'center'
  },
  blockRight: {
    display: 'block',
    float: 'right'
  },
  center: {
    textAlign: 'center'
  }
}

export default class PaymentForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      cardBrand: "",
      nonce: undefined,
      googlePay: false,
      applePay: false,
      masterpass: false,
      prospectData:false,
      buttonProcess:false,
      informrender:this.props.informrender ?this.props.informrender :false,
      hideskeleton : false,
      skeletonHtml:this.props.skeletonHtml ?this.props.skeletonHtml :false,
    }
    this.requestCardNonce = this.requestCardNonce.bind(this);
    this.managePostalCode = this.managePostalCode.bind(this);
    this.buildForm = this.buildForm.bind(this);

  }

  async requestCardNonce(){
    this.setState({buttonProcess:true});
    await this.paymentForm.requestCardNonce((res)=>{
      console.log(res);
    })
  }

  async componentDidMount(){
    try{

      var prospectData = await Checkout.getProspect();

      if(!prospectData){
        this.props.closeWindow();
      }
      prospectData.orderTotal = await Checkout.getOrderTotal();

      if(!prospectData.orderTotal){
        this.props.closeWindow();
      }

      this.setState({prospectData:prospectData});
      var informrender = this.state.informrender;

      
      const squarePaymentForm  = new this.props.paymentForm({
        applicationId:  (this.props.cartInfo && this.props.cartInfo.application_id ? this.props.cartInfo.application_id :''), 
        locationId: (this.props.cartInfo && this.props.cartInfo.location_id ? this.props.cartInfo.location_id :''),
        inputClass: "sq-input",
        autoBuild: false,
        inputStyles: [
          {
            fontSize: "16px",
            fontFamily: "Helvetica Neue",
            padding: "16px",
            color: "#373F4A",
            backgroundColor: "transparent",
            lineHeight: "1.15em",
            placeholderColor: "#000",
            _webkitFontSmoothing: "antialiased",
            _mozOsxFontSmoothing: "grayscale"
          }
        ],
        applePay: {
          elementId: 'sq-apple-pay'
        },
        cardNumber: {
          elementId: "sq-card-number",
          placeholder: "• • • •  • • • •  • • • •  • • • •"
        },
        cvv: {
          elementId: "sq-cvv",
          placeholder: "CVV"
        },
        expirationDate: {
          elementId: "sq-expiration-date",
          placeholder: "MM/YY"
        },
        postalCode: {
          elementId: "sq-postal-code",
          placeholder: "Enter billing zip code"
        },
        callbacks: {
          methodsSupported: (methods) => {
            if(methods.googlePay){
              this.setState({
                googlePay: methods.googlePay
              })
            }
            if(methods.applePay){
              this.setState({
                applePay: methods.applePay
              })
            }
            if(methods.masterpass){
              this.setState({
                masterpass: methods.masterpass
              })
            }
            return;
          },
          createPaymentRequest: () => {
            return {
              requestShippingAddress: false,
              requestBillingInfo: false,
              currencyCode: "USD",
              countryCode: "US",
              total: {
                label: (prospectData && prospectData.firstName ? prospectData.firstName :'')+' '+(prospectData && prospectData.lastName ? prospectData.lastName :''),
                amount: (prospectData && prospectData.orderTotal ? prospectData.orderTotal :''),
                pending: false
              },
              lineItems: [
                {
                  label: "Subtotal",
                  amount: (prospectData && prospectData.orderTotal ? prospectData.orderTotal :''),
                  pending: false
                }
              ]
            };
          },
          cardNonceResponseReceived:async (errors, nonce, cardData) => {
            if (errors) {
              // Log errors from nonce generation to the Javascript console
              errors.forEach(function(error) {
                console.log("  " + error.message);
              });
              this.setState({buttonProcess:false});
              return;
            }
            this.setState({
              nonce: nonce,
            })
            let nonceDetail =await Checkout.saveSquareNonce(nonce);
            this.props.getSquareRes(nonce);            
            if(nonceDetail){
              if(this.state.informrender === true){
                
                this.props.squareCallBackForRenderForm(nonceDetail);
                var thisRef = this;
                var intervl = setInterval(function(){ 
                  if(ls.get("informSquareLadaButtonStatus") === true){
                    thisRef.setState({buttonProcess:false});
                    clearInterval(intervl);
                  }
                 }, 2000);

              }else{
                //close the window
                this.props.closeWindow();
              }
              
            }
            
          },
          unsupportedBrowserDetected: () => {
          },
          inputEventReceived: async (inputEvent) => {

            switch (inputEvent.eventType) {
              case "focusClassAdded":
                break;
              case "focusClassRemoved":
                break;
              case "errorClassAdded":
                document.getElementById("error").innerHTML =
                  "Please fix card information errors before continuing.";
                break;
              case "errorClassRemoved":
                document.getElementById("error").style.display = "none";
                break;
              case "cardBrandChanged":
                if(inputEvent.cardBrand !== "unknown"){
                  this.setState({
                    cardBrand: inputEvent.cardBrand
                  })
                } else {
                  this.setState({
                    cardBrand: ""
                  })
                }
                break;
              case "postalCodeChanged":
                
                break;
              default:
                break;
            }
          },
          paymentFormLoaded: function() {
            
            if(informrender !== true){
            }
          }
        }
      });



      this.paymentForm = squarePaymentForm; 
      let _this = this; 
      _this.buildForm(this.paymentForm).then(function(){
        var clear =  setInterval(function () {
          var ccFieldWrapper = document.getElementById('cc-field-wrapper');
          if(ccFieldWrapper.innerHTML !== "" && ccFieldWrapper.getElementsByTagName("iframe").length > 0){
            _this.setState({hideskeleton: true});     
            clearInterval(clear);
          }
        },100);
      }) 
    }catch(e){
      this.props.closeWindow();
    }
    
  }

  async managePostalCode(formElement){
    try{
        if(this.state.prospectData && this.state.prospectData.zip && formElement && formElement.postalCodeValue && formElement.postalCodeValue!==""){
          let prospectData = await Checkout.getProspect();
          prospectData.zip = formElement.postalCodeValue;
          Checkout.updateCartInfo(prospectData);
        }
        
    }catch(e){
      //
      console.log("Error",e);
    }
  }

  async buildForm(paymentForm){
    let status = await paymentForm.build();
    return status;
  }

  render(){
    
    return (
      <div className="container" >
        <div className={(this.state.hideskeleton === true) ? 'd-none' : ""}>
          {this.state.skeletonHtml}
        </div>
        <div id={this.state.informrender !== true ? "form-container" : ""} style={{"marginTop": this.state.informrender !== true ? "60%" : "0px"}} className={(this.state.hideskeleton === false) ? 'd-none' : ""}>
          <div id="sq-walletbox">
            <p className="text-center"><img src={squareImg} style={{height:this.state.informrender!==true ? "110px": "57px"}} alt=""/></p>

            {this.state.informrender !== true && <div>   
                            <button style={{display: (this.state.applePay) ? 'inherit': 'none'}}
                            className="wallet-button"
                            id="sq-apple-pay"></button>
                            <button style={{display: (this.state.masterpass) ? 'block': 'none'}}
                            className="wallet-button"
                            id="sq-masterpass"></button>
                            <button style={{display: (this.state.googlePay) ? 'inherit': 'none'}}
                            className="wallet-button"
                            id="sq-google-pay"></button>
                    </div>}
            
            <hr/>
          </div>

          <div id="sq-ccbox">
            <p className="text-center">
            <h3>Enter Card Info Below </h3>
              <span style={styles.leftCenter} ></span>
              <span style={styles.blockRight}>
                {this.state.cardBrand.toUpperCase()}
              </span>
            </p>
            <div id="cc-field-wrapper">
              <div id="sq-card-number"></div>
              <input type="hidden" id="card-nonce" name="nonce" />
              <div id="sq-expiration-date"></div>
              <div id="sq-cvv"></div>
            </div>
            <input
              id="name"
              type="text"
              placeholder="Name"
              style={{display:"none"}}
              value={ (this.state.prospectData && this.state.prospectData.firstName ? this.state.prospectData.firstName :'' ) +' '+ (this.state.prospectData && this.state.prospectData.lastName ? this.state.prospectData.lastName :'' ) }
            />
            <div id="sq-postal-code"></div>
          </div>

          <LaddaButton
          style={{marginTop:"20px"}}
          className="bg-blue"
          type="submit"
          loading={this.state.buttonProcess}
          data-size={L}
          data-style={SLIDE_UP}
          data-spinner-size={30}
          data-spinner-color="#ddd"
          data-spinner-lines={12}
          onClick={this.requestCardNonce}
          >
          Pay now
          </LaddaButton>

        </div>
        <p style={styles.center} id="error"></p>
      </div>
    )
  }
}
