import React, { Component } from "react";
import Checkout from "../classes/checkout";
import withUnmounted from '@ishawnwang/withunmounted'
import ls from "local-storage";
ls.backend(sessionStorage);

class ShippingPage extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      cartInfo: { shippingMethods: {} },
      shippingValue: "",
      emptyShipping: false
    };
  }

  async componentWillReceiveProps(props) {
    if (props.cart) {
      this.setState({ cartInfo: props.cart });
    }
    if (props.prospectData) {
      let prospectData = await Checkout.getProspect();
      this.setState({ shippingValue: prospectData.shipping });
    }
  }

  async updateTax(){
    let tax = await Checkout.calculateTax();
    this.props.updateTax(tax)
  }

  handleChange(event) {
    let cart = this.state.cartInfo;
    cart.cart.shipping = event.target.value;
    this.props.updateShipping({ cartInfo: cart , shipping: event.target.value });
    Checkout.saveShipping({ shipping: event.target.value });
    
    this.setState({ shippingValue: event.target.value });
    this.setState({ emptyShipping: false });
    this.updateTax()
  }

  getshippingMulti(){
    try{
      let cart = this.state.cartInfo;
      let offerDetails = cart && 'offerDetails' in cart && cart.offerDetails ? cart.offerDetails : {};
      let allCycle = 0;
      
      Object.keys(offerDetails).forEach((key)=>{
        let prepaid  = offerDetails[key] && 'prepaid' in offerDetails[key] ? offerDetails[key]['prepaid'] : null;;
        
        if(prepaid){
          let cycles = prepaid && 'terms' in prepaid && prepaid['terms'] && prepaid['terms'].length >0 && prepaid['terms'][0] && prepaid['terms'][0]['cycles']? prepaid['terms'][0]['cycles'] : 0;
          allCycle = allCycle + cycles;
        }
      });
      return allCycle;
    }catch(e){
      console.log("Error:",e);
    }
  }
  
  getCountryBasedShipping(shippings){

    try{
      let cart = this.state.cartInfo;
      let checkout_country_based_shipping_enable = cart  && 'checkout_country_based_shipping_enable' in  cart && cart.checkout_country_based_shipping_enable ?cart.checkout_country_based_shipping_enable : false;

      if(!checkout_country_based_shipping_enable) return shippings;

      let prospectData = ls.get("customerInfo");

      let prospectCountry = prospectData && 'country' in prospectData &&  prospectData.country ?prospectData.country : "";

      if(prospectCountry === "") return shippings;

      let countryData = this.props.countryData;

      let country = countryData && countryData.find(ele=> ele && 'code2' in ele && ele.code2 === prospectCountry);

      let countryName = country && country.name ? country.name : "";

      
      if(countryName === ""){

        return shippings;

      }



      let country_based_shipping = cart && 'country_based_shipping' in cart && cart.country_based_shipping ? cart.country_based_shipping : [];

      if(!country_based_shipping) return shippings;

      for(let i=0;i<country_based_shipping.length ; i++){
        let countryList = country_based_shipping[i]['countries'];
        let shippingMethods = country_based_shipping[i]['shipping_methods'];
        if(countryList.includes(countryName)){
          shippings = shippingMethods;
          break;
        }

      }

      return shippings;

    }catch(e){

      console.log("Error on country listing",e);

      return shippings;
    }
  }

  render() {
    
    let shippings =  this.state.cartInfo.shippingMethods ? this.state.cartInfo.shippingMethods : {};
    let shippingUI = [];
    let cycle = this.getshippingMulti();
    
    shippings = this.getCountryBasedShipping(shippings);

    //for v2 shipping configuration
    let shippingOnCheckoutConfig = ls.get("shippingOnCheckoutConfig");
    shippings = shippingOnCheckoutConfig && shippingOnCheckoutConfig.length ? shippingOnCheckoutConfig : shippings;

    shippings && Object.keys(shippings).forEach((key)=>{
      shippingUI.push(
                    <div className={`custom-control custom-radio  ${this.state.cartInfo.checkout_enable_single_page ? 'op-border-black' :'notonepage'}`} key={key}>
                        <input
                          id={key}
                          name="shipping"
                          type="radio"
                          className="custom-control-input"
                          value={shippings[key]['shipping_method_crm_id']}
                          checked={this.state.shippingValue === shippings[key]['shipping_method_crm_id']}
                          onChange={this.handleChange}
                        />
                        <label className="custom-control-label" htmlFor={key}>
                          <span className={"label-position " + ( shippings[key]['shipping_method_name'].length > 24 ? "custom-tooltip": "")} data-title={shippings[key]['shipping_method_name']}>{ Checkout.setEcllipseOnString(shippings[key]['shipping_method_name'], 50) }</span>
                          <p className="price-position">${parseFloat( (cycle > 0 ? shippings[key]['initial_amount'] * cycle : shippings[key]['initial_amount']),10).toFixed(2)}</p>
                        </label>
                  </div>
              );
    });


    if(this.state.cartInfo.token_based_user_free_shipping){
      shippingUI = (
        <div className={`custom-control custom-radio text-center text-success  ${this.state.cartInfo.checkout_enable_single_page ? 'op-border-black' :'notonepage'}`} key={Math.random()}>
          Free shipping applied!
        </div>
      );
    }

    if(shippingUI.length<1){
      shippingUI.push(
        <div className={`custom-control custom-radio text-center text-danger  ${this.state.cartInfo.checkout_enable_single_page ? 'op-border-black' :'notonepage'}`} key={Math.random()}>
          Shipping is unavailable
        </div>
      );
    }
   
    return (
      <div>
        <div className="contact-information" ref={this.props.scrollShippingPageDiv} >
          <h3>Shipping Method &nbsp;{this.state.cartInfo.checkout_enable_single_page ?<span data-toggle="tooltip" className="tool_tip span-cursor" title="Select the suitable shipping method for your order."><i className="fa fa-question-circle "></i></span> : ''}</h3>

          <div className="contact-form billing-form">
            {
            shippingUI
              }
          </div>
          {this.state.emptyShipping === true ? (
            <div className="shipping-validation error">
              Plesae select a valid shipping method
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default withUnmounted(ShippingPage);