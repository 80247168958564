import React, { Component,createRef } from "react";
import Checkout from "../classes/checkout";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import ShippingPage from "./ShippingPage";
import CheckoutGiftPage from "./CheckoutGiftPage";
import LaddaButton, {  L, SLIDE_UP } from "react-ladda";
import Places from "../classes/places";
import {connect} from 'react-redux';
import withUnmounted from '@ishawnwang/withunmounted'
import swal from 'sweetalert';
import payPal from "../classes/payPalWindow"
import paypallogo from "../images/paypal-logo-.png";
import amazonPayLogo from "../images/amazon-payments.png";
import squareImg from "../images/square.png";
import env from '../env';
import googleInstance from "../classes/google"
import ls from "local-storage";
import AmazonPayProcess from './AmazonPayProcess'
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

import ProcessSquare from './ProcessSquare';
import GiftForm from './GiftForm';
import TandC from './TandC';
import OneClickUpsell from './OneClickUpsell';
import validator from 'validator';

import PaymentMethodsAddresses from "./PaymentMethodsAddresses";
import LeftSkeleton from "./LeftSkeleton";
ls.backend(sessionStorage);

const mapStateToProps = (state) => {
  return {
      llConfig: state.llConfig,
      storeCountries: state.countries
  };
}

class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.scrollShippingPageDiv = createRef();
    this.scrollCardFormDiv = createRef();
    this.billingChange = this.billingChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addSlash = this.addSlash.bind(this);
    this.requiredCardData = this.requiredCardData.bind(this);
    this.updateShippingM = this.updateShippingM.bind(this);
    this.onChange = this.onChange.bind(this);
    this.modalWindow = null;
    this.checkoutNextProcess = this.checkoutNextProcess.bind(this);
    this.validateProspect = this.validateProspect.bind(this);
    this.onGetCardNonce =  this.onGetCardNonce.bind(this);
    this.handleSquareSubmit = this.handleSquareSubmit.bind(this);
    this.validateExpressCheckoutStatus = this.validateExpressCheckoutStatus.bind(this);
    this.validateZipCode =this.validateZipCode.bind(this);
    this.squareCallBackForRenderForm = this.squareCallBackForRenderForm.bind(this);
    this.getUpdateProps = this.getUpdateProps.bind(this);  
    this.addNewBillingAddress = this.addNewBillingAddress.bind(this);  
    

    this.state = {
      payPalLoading : false,
      squareLoading:false, 
      amazonLoading: false, 
      stateData: [{ code: "", name: "Select State" }],
      emptyStateArr: [{ code: "", name: "Select State" }],
      conditionalCardValidation: false,
      initialValues: {},
      shippingDetails: {},
      useDiffBilling: false,
      validCard: false,
      sameAsBilling: "YES",
      errSameAsBilling: false,
      cc_number: "",
      cc_exp_date: "",
      cc_holder_name: "",
      cc_cvv: "",
      cardErr: {
        cc_number: { status: false, message: "" },
        cc_holder_name: { status: false, message: "" },
        cc_exp_date: { status: false, message: "" },
        cc_cvv: { status: false, message: "" }
      },
      cardErrMsg: {
        cc_number: "Enter valid card number",
        cc_holder_name: "Enter valid holder's name",
        cc_exp_date: "Enter valid expiration date",
        cc_cvv: "Enter valid security code"
      },
      billingFirstName:'',
      billingLastName:'',
      billingAddress1:'',
      billingAddress2:'',
      billingCity:'',
      billingState:'',
      billingZip:'',
      billingCountry:'',
      phone:'',
      customfields: '',
      billingError:{
          billingFirstName:{status:false, message:"Enter a first name"},
          billingLastName:{status:false, message:"Enter a last Name"},
          billingAddress1:{status:false, message:"Enter an address"},
          billingCity:{status:false, message:"Enter a city"},
          billingState:{status:false, message:"Select a state / province"},
          billingZip:{status:false, message:"Invalid zip code"},
          billingCountry:{status:false, message:"Select a country"},
          phone:{status:false, message:"Phone number is not valid"}
      },
      cart: (this.props.llConfig.cartData) ? this.props.llConfig.cartData : {},
      countryData:this.props.storeCountries.countries,
      x_transaction_id:'id-' + Math.random().toString(36).substr(2, 16),
      threeDsLoaded:false,
      kountLoaded:false,
      expressCheckoutEnable:false,
      enableCCForm:false,
      expressCount:0,
      isOnlySquare:false,
      show_gift_form:false,
      gift_email:"",
      gift_message:"",
      giftError:{
        gift_email:{status:false,message:""},
        gift_message:{status:false,message:""}
      },
    };
    this.props.updateStep("pay");
  }

  tokenUserPaymentBillingAddChange =(billingAddressDetail,sameAsBilling)=>{
  if(billingAddressDetail){
      this.setState({
        billingFirstName: billingAddressDetail[0] ,
        billingLastName:billingAddressDetail[1] ,
        billingAddress1: billingAddressDetail[2],
        billingAddress2: billingAddressDetail[3],
        billingCity:billingAddressDetail[4],
        billingState: billingAddressDetail[6].toUpperCase(),
        billingZip:billingAddressDetail[5],
        billingCountry: billingAddressDetail[7].toUpperCase(),
        phone : billingAddressDetail[8],
        sameAsBilling:sameAsBilling
      })
    }else{
      this.setState({
        billingFirstName:  '',
        billingLastName: '',
        billingAddress1:  '',
        billingAddress2:  '',
        billingCity: '',
        billingZip: '',
        phone :  '',
        sameAsBilling:sameAsBilling
      })
    }
  }
  tokenUserPaymentMethod =()=>{
    this.props.showTokenUserPaymentMethod(true)
  }
  addNewBillingAddress(res){
    this.setState({
      sameAsBilling:res
    })
  }
  getUpdateProps(e){
    let oneClickUpsells = ls.get("oneClickUpsells");
    this.props.updateTax(e);
    this.setState({oneClickUpsells:oneClickUpsells});
  }

  async validateExpressCheckoutStatus(cartInfo){
    try{
      let returnStatus = false;
      if(!cartInfo){
        return returnStatus;
      }
      let expressCount = 0;
      let gatewayArray = ["PayPal","Square","AmazonPay"];
      let checkout_card_type_accepted = cartInfo.checkout_card_type_accepted;
      let isOnlySquare = false;

      gatewayArray.forEach(function(arrKey){
        if(checkout_card_type_accepted.indexOf(arrKey)>-1){
          returnStatus = true;
          ++expressCount; 
        }
      })

      if(checkout_card_type_accepted && checkout_card_type_accepted.length === 1 && checkout_card_type_accepted.indexOf("Square")>-1){
        isOnlySquare = true;
      }
      this.setState({expressCount:expressCount,isOnlySquare:isOnlySquare});
      
      return returnStatus;
    }catch(e){
      console.log("Error:",e);
    }
  }


  async validateCCFormStatus(cartInfo){
    try{
      let returnStatus = false;
      if(!cartInfo){
        return returnStatus;
      }

      let CCgatewayArray = ["Visa", "Mastercard","Discover", "Amex"];
      let checkout_card_type_accepted = cartInfo.checkout_card_type_accepted;
      CCgatewayArray.forEach(function(arrKey){
        if(checkout_card_type_accepted.indexOf(arrKey)>-1){
          returnStatus = true;
        }
      })
      return returnStatus;
    }catch(e){
      return false;
    }
  }

  async componentWillReceiveProps(nextProps){
   

    if(nextProps && nextProps.storeCountries){
      var list = nextProps.storeCountries.countries;
      this.setState({
        countryData: list
      });
      if (this.state.selectedCountry && this.state.selectedCountry.length > 0) {
        this.handleChange(this.state.selectedCountry);
        this.handleCountryChange(this.state.selectedCountry);
      }
    }
    if(nextProps && nextProps.cartInfo){
      let expressCheckout = await this.validateExpressCheckoutStatus(nextProps.cartInfo);
      let enableCCForm = await this.validateCCFormStatus(nextProps.cartInfo);

      let threeDsLoaded = this.state.threeDsLoaded;
      let kountLoaded = this.state.kountLoaded ;

      if("manage3DSecure"  in window && nextProps.cartInfo.checkout_enable_3d_verify && this.state.threeDsLoaded === false){
        threeDsLoaded = true;

        window["manage3DSecure"](env, nextProps.cartInfo);
      }

      if("loadKountJsDependency" in window && nextProps.cartInfo.checkout_enable_kount && this.state.kountLoaded === false){
        kountLoaded=true;
        window["loadKountJsDependency"](env,nextProps.cartInfo);
      }

      this.setState({cart:{cartInfo:nextProps.cartInfo} ,threeDsLoaded : threeDsLoaded,kountLoaded:kountLoaded,expressCheckoutEnable:expressCheckout, enableCCForm:enableCCForm})
    }
  }

  async componentDidMount(){
  
    if(this.props.exitPopupVisiblity === true){
      this.props.exitIntentShow()
    }
    let cart = (this.state.cart) ? this.state.cart : await Checkout.getCartDataAtLocal();
    
    if(!cart){
      return 0;
    }
    let initVal = await Checkout.getProspect();
    let responseVal = await Checkout.validateOrderPage();

 

    if(responseVal){
      const { history } = this.props;
      history.push("/upsell");
    }

    let shipp=[];
    this.setState({ initialValues: initVal,shippingDetails: shipp ,cart: cart, billingCountry: initVal.country });
    this.handleCountryChange(initVal.country);
  }

  async componentWillMount() {
    try{
        let llHash = this.props.llConfig.llHash;
        if(!this.props.llConfig.cartData || (this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_enable_single_page !== 1))
        {
          await this.props.initiateConfig(llHash);
        }
    }catch(e){
        console.log("Error",e);
    }
  }

  
  toggle() {
    this.setState({
      loading: !this.state.loading,
      progress: 0.5
    });
  }

  togglePaypal() {
    this.setState({
      payPalLoading: !this.state.payPalLoading,
      progress: 0.5
    });
  }
  
  toggleAmazon(){
    this.setState({
      amazonLoading: !this.state.amazonLoading,
      progress: 0.5
    });
  };

  toggleSquare(){
    //squareLoading
    this.setState({
      squareLoading: !this.state.squareLoading,
      progress: 0.5
    });
  }

  billingChange(event) {
    
    let billingChnage = this.state.sameAsBilling==="YES" ? "NO" : "YES"
    var use_diff_billing = false;
    if (billingChnage === "NO") {
      this.props.setPopException(this.state.exitPopupVisiblity)
      use_diff_billing = true;
      this.setState({
        billingFirstName: "",
        billingLastName:"",
        billingAddress1:"",
        billingAddress2:"",
        billingCity:"",
        billingZip:"",
        phone : ""
      })
    }else{ 
      this.setState({
        billingFirstName: "",
        billingLastName:"",
        billingAddress1:"",
        billingAddress2:"",
        billingCity:"",
        billingZip:"",
        phone : ""
      })
    }
    
    this.setState({
      useDiffBilling: use_diff_billing,
      sameAsBilling: billingChnage,
      errSameAsBilling: false
    });
  }

  validateEmail(email) {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  requiredCardData() {
    let valid = true;
    let initErr = {
      cc_number: { status: false, message: "" },
      cc_holder_name: { status: false, message: "" },
      cc_exp_date: { status: false, message: "" },
      cc_cvv: { status: false, message: "" }
    };

    let giftError = {
      gift_email:{status:false,message:""},
      gift_message:{status:false,message:""}
    }

    if(this.state.show_gift_form ==='yes'){
      if(this.validateEmail(this.state.gift_email)!==true){
        giftError.gift_email.status= true;
        giftError.gift_email.message="Enter valid gift email"
      }

      if(this.state.gift_message===""){
        giftError.gift_message.status= true;
        giftError.gift_message.message="Enter valid gift message"
      }

    }  

    let previousOrderId = ls.get("previousOrderId");
   
    if(previousOrderId === null  && this.props.showTokenUserPayment){
      swal("Please select the payment type", {
        icon: "error",
      });
      return false;
    }
    for (var key in initErr) {
     
      if(!this.props.showTokenUserPayment){
      if (!this.state[key].length) {
          valid = false;
          initErr[key].status = true;
          initErr[key].message = this.state.cardErrMsg[key];
      }

      if(key === "cc_number" && (!this.state[key].length > 0 || !validator.isCreditCard(this.state[key]))) {
        valid = false;  
        initErr[key].status = true;
        if (this.state.conditionalCardValidation && this.state.conditionalCardValidation !== false && this.state.conditionalCardValidation.indexOf("cc_number") > -1) {
          if(this.state.cardErr[key].status !== true){
            initErr[key].status = false;
          }
        }
        initErr[key].message = "Enter valid card number";
      }

      if (key === "cc_exp_date" && !this.state[key].length > 0) {
        valid = false;  
        initErr[key].status = true;
        if (this.state.conditionalCardValidation && this.state.conditionalCardValidation !== false && this.state.conditionalCardValidation.indexOf("cc_exp_date") > -1) {
          if(this.state.cardErr[key].status !== true){
            initErr[key].status = false;
          }
        }
        initErr[key].message = "Enter valid expiration date";
      }

      if (key === "cc_holder_name" && (!this.state[key].length > 0 || !this.state[key].match(/^[A-Za-z ]*$/))) {
        valid = false;  
        initErr[key].status = true;
        if (this.state.conditionalCardValidation && this.state.conditionalCardValidation !== false && this.state.conditionalCardValidation.indexOf("cc_holder_name") > -1) {
          if(this.state.cardErr[key].status !== true){
            initErr[key].status = false;
          }
        }
        initErr[key].message = "Enter valid card holder";
      }
  
      if (key === "cc_cvv" && (!this.state[key].match(/^[0-9]{3,4}$/) )) {
        valid = false;
        initErr[key].status = true;
        if (this.state.conditionalCardValidation && this.state.conditionalCardValidation !== false && this.state.conditionalCardValidation.indexOf("cc_cvv") > -1) {
          if(this.state.cardErr[key].status !== true){
            initErr[key].status = false;
          }
        }
        initErr[key].message = "Enter valid card cvv";
      }
  }
}

    if (this.state.sameAsBilling.length && this.state.sameAsBilling!=='YES') {
      let billingError = this.state.billingError;
      
      for (var billKey in billingError) {
        if (!this.state[billKey].length) {
          valid = false;
          billingError[billKey].status = true;
        }else{
          billingError[billKey].status = false;
        }

        switch(billKey){
          case "billingFirstName" :
            if(!this.state[billKey].match(/[A-Za-z]/i)){
              valid = false;
              billingError[billKey].status = true;
              billingError[billKey].message = "Enter valid first name";
            }else{
              billingError[billKey].status = false;
            }
            break;
          
          case "billingLastName" :
            if(!this.state[billKey].match(/[A-Za-z]/i)){
              billingError[billKey].status = true;
              valid = false;
              billingError[billKey].message = "Enter valid last name";
            }else{
              billingError[billKey].status = false;
            }
            break;
            
          case "billingCity" :
            if(!this.state[billKey].match(/[A-Za-z]/i)){
              valid = false;
              billingError[billKey].status = true;
              billingError[billKey].message = "Enter valid city";
            }else{
              billingError[billKey].status = false;
            }
            break;
          
          case "billingState":
              if(this.state[billKey].length < 1 && !this.state.billingState){
                valid = false;
                billingError[billKey].status = true;
                billingError[billKey].message = "Select a state / province";
              }
              
          break;
            
          case "billingAddress1" :
            if (this.state[billKey] === "") {
              billingError[billKey].status = true;
              billingError[billKey].message = "Enter valid address";
            }else{
              billingError[billKey].status = false;
            }
            break;
          case "billingZip" :
            if(!this.validateZipCode(this.state.billingCountry,this.state[billKey])){
              valid = false;
              billingError[billKey].status = true;
              billingError[billKey].message = "Enter valid ZIP code";
            }else{
              billingError[billKey].status = false;
            }
            break;
          
          case "phone" :
            if (!this.state[billKey].match(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/)) {
              valid = false;
              billingError[billKey].status = true;
              billingError[billKey].message = "Enter valid phone number";
            }else{
              billingError[billKey].status = false;
            }
            break;  
          
          default :
            break;
        }  
      }

      this.setState({
        billingError,
        cardErr: initErr,
        giftError:giftError
      });
      
    }else{
      this.setState({
        cardErr: initErr,
        giftError:giftError
      });
    }
    return valid;
  }

  updateShippingM(cart) {

    let intVal = this.state.initialValues;
    intVal.shipping = cart.shipping;
    this.setState({initialValues:intVal});
    this.props.updateShipping(cart);
  }

  cardDateFormate(dateVal){
    dateVal =  dateVal.replace('/','');
    dateVal = dateVal.length < 4 ? '0'+ dateVal.toString() : dateVal;
    return dateVal; 
  }

  async handleSquareSubmit(e){
    try{
      e.preventDefault();

      if(this.props && this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_card_type_accepted && this.props.llConfig.cartData.checkout_card_type_accepted.find(ele=>(ele==="Square")) !== "Square"){
        return false;
      }

      if(await this.validateProspect()!==true){
        return false;
      }

      let proceedToPaymentStat = Checkout.checkProcessForPaymentorNot();
      if(!proceedToPaymentStat){
        swal("Your cart is empty!", {
          icon: "error",
        });
        return false;
      }
      
      let application_id = this.props && this.props.llConfig.cartData && this.props.llConfig.cartData.application_id ? this.props.llConfig.cartData.application_id :'';
      let location_id =  this.props && this.props.llConfig.cartData && this.props.llConfig.cartData.location_id ? this.props.llConfig.cartData.location_id :'';
      
      if(application_id==='' || location_id===''){
        swal("Square service is not available, please continue with other payment option",{
          icon:"error",
        });
        return 0;
      }
      let prospectData = await Checkout.getProspect();
      await googleInstance.beginProcess('add_payment_info');
      if(!prospectData){
        return 0;
      }
      
      
      let callResponse = {
        url:window.location.origin+'/process-square'
      };

      await payPal.processPrivateWindow(callResponse);
      let squareNonce = await Checkout.getSquareNonce();      
      await this.squareProcessPayment(squareNonce);
    }catch(e){
      //
      console.log("Error :",e);
    }
  }

  async squareProcessPayment(squareNonce){
    try{
      if(squareNonce){

        if(await this.validateProspect()!==true){
          return false;
        }
        
        swal("We are processing your order, Please don't close your window",{
          closeOnClickOutside: false,
          closeOnEsc:false,
          cancel: false,
          buttons: false,
          icon:"info",
          
        });

        let values =await this.getFormParam();
        values.billingZip = values.zip;
        values.square_token = squareNonce;
        values.endPoint = 'process-onepage-checkout';
        values.creditCardType = 'offline';
        values.billingSameAsShipping = this.state.sameAsBilling;
        
        values.shippingAddress1= values.address1;
        values.shippingAddress2=values.address2;
        values.shippingCity = values.city;
        values.shippingState = values.state;
        values.shippingZip = values.zip;
        values.shippingCountry = values.country;
        values.phone = values.phone ? values.phone :"";
        values.email=values.email ? values.email : "";
        values.tranType="sale";
        values.payment_page_custom_fields = this.state.customfields;
        
        if(values.billingSameAsShipping === "NO"){
          let billingaddress = {
            billingFirstName: this.state.billingFirstName,
            billingLastName: this.state.billingLastName,
            billingAddress1: this.state.billingAddress1,
            billingAddress2: this.state.billingAddress2,
            billingCity: this.state.billingCity,
            billingState: this.state.billingState,
            billingZip: this.state.billingZip,
            billingCountry: this.state.billingCountry,
            phone: this.state.phone,
          }
          values.billingZip = this.state.billingZip;
          ls.set("billingAddress", billingaddress)
        }

        let processRes = await Checkout.processOrder(values);
        
        if(processRes){
          ls.set('paymentType', "Square")
          Checkout.removeNonce();
          await this.checkoutNextProcess(values);
          await googleInstance.beginProcess('checkout_progress');
          swal.close();
        }else{
          swal.close();
          if(processRes !== null){
            swal("Something went wrong while processing your order, please try again later.",{
              icon:"error",
            });
          }
        }
      }
    }catch(e){
      console.log(e,"Error");
    }
  }

  async handleSubmit(props, values, actions) {
    if(await this.validateProspect()!==true){
      return false;
    }
    
    this.setState({conditionalCardValidation : false });
    if(this.props.showTokenUserPayment){
      this.setState({conditionalCardValidation : true });
    }
    if (this.requiredCardData() === false) {
      this.scrollCardFormDiv && this.scrollCardFormDiv.current && this.scrollCardFormDiv.current.scrollIntoView({ behavior: 'smooth' })
      return false;
    }
    
    let proceedToPaymentStat = Checkout.checkProcessForPaymentorNot();
    if(!proceedToPaymentStat){
        swal("Your cart is empty!", {
            icon: "error",
        });
        return false;
    }

    this.toggle();

    values =await this.getFormParam();

    values.creditCardNumber = this.state.cc_number;
    values.expirationDate = this.cardDateFormate(this.state.cc_exp_date);
    values.cc_holder_name =  this.state.cc_holder_name;
    values.billingSameAsShipping =this.state.sameAsBilling;
    values.cvv = this.state.cc_cvv;
    values.payment_page_custom_fields = this.state.customfields;
    
    await googleInstance.beginProcess('add_payment_info');
    values.creditCardType = Checkout.getCardType(values.creditCardNumber);


    if(values.billingSameAsShipping === "NO"){
      let billingaddress = {
        billingFirstName: this.state.billingFirstName,
        billingLastName: this.state.billingLastName,
        billingAddress1: this.state.billingAddress1,
        billingAddress2: this.state.billingAddress2,
        billingCity: this.state.billingCity,
        billingState: this.state.billingState,
        billingZip: this.state.billingZip,
        billingCountry: this.state.billingCountry,
        phone: this.state.phone,
      }
      ls.set("billingAddress", billingaddress)
    }
    
    //after payment
    let processRes = await Checkout.processOrder(values);
    ls.set('paymentType',"Card Checkout")
    if(processRes){
      await googleInstance.beginProcess('checkout_progress');
      await this.checkoutNextProcess(values);
    }else{
      this.toggle();
    }

  }

  async getFormParam(){
    try{
        let prospect = await Checkout.getProspect();
        let values = prospect
      
        values.billingFirstName =  this.state.billingFirstName;
        values.billingLastName = this.state.billingLastName;
        values.billingAddress1 = this.state.billingAddress1;
        values.billingAddress2 = this.state.billingAddress2;
        values.billingCity = this.state.billingCity;
        values.billingState = this.state.billingState;
        values.billingZip = this.state.billingZip;
        values.billingCountry = this.state.billingCountry;
        values.phone = this.state.phone ? this.state.phone : values.phone;
        values.shippingId = (prospect && prospect.shipping) ? prospect.shipping : "" ;
        
        values.payment_page_custom_fields = this.state.customfields;
        if("getThreedsHashDetail" in window && this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_enable_3d_verify && this.state.threeDsLoaded === true){
          let three3d = window["getThreedsHashDetail"]();
          values['3d_version'] = three3d && three3d.protocolVersion ? three3d.protocolVersion : '';
          values['ds_trans_id'] = three3d && three3d.dsTransId ? three3d.dsTransId : '';
          values['acs_trans_id'] = three3d && three3d.acsTransId ? three3d.acsTransId : '';
          values['cavv'] = three3d && three3d.authenticationValue ? three3d.authenticationValue : '';
          values['eci']= three3d && three3d.eci ? three3d.eci : '';
        }

        //sessionID checkout_enable_kount

        if("getMercSessId" in window && this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_enable_kount && this.state.kountLoaded === true){
          values['sessionID'] = window["getMercSessId"]();
        }

        //for gift email
        if(this.state.show_gift_form && this.state.show_gift_form==="yes"){
          values['gift']=  {email:this.state.gift_email,message:this.state.gift_message};
          
        }
     
        return values;
    }catch(e){
      console.log("Param",e);
      //error
    }
  }

  async checkoutNextProcess(values){
    try{
      const { history } = this.props;
      let cartInfo = this.props.llConfig.cartData ? this.props.llConfig.cartData.processUpsell : false;
      let res = false;
      if(cartInfo){
        res = await Checkout.getUpsells(values);
      }
      
      if(!res){
        history.push("/thank-you");
      }else{
        await Checkout.setUpsellIndex(0);
        history.push("/upsell");
      }
    }catch(e){
      console.log("Error",e);
    }
  }

  onChange(e) {

    let str = e.target.value;
    if(['billingFirstName', 'billingLastName', 'billingCity', 'billingAddress1', 'billingAddress2'].indexOf(e.target.name) > -1){
      str = Checkout.validateSpaceForInput(str, true);
    }else{
      if(e.target.name !== 'cc_holder_name' && e.target.name !== 'gift_message'){
        str = Checkout.validateSpaceForInput(str, false);
      }else{
        str = str? str :"";
      }
    }
    this.setState({ [e.target.name]: str });
    let billingError = this.state.billingError;
    let giftError = this.state.giftError;

      switch(e.target.name){
        case "billingFirstName" :
          if(!str.match(/^[a-zA-Z_ ]*$/)){
            billingError[e.target.name].status = true;
            billingError[e.target.name].message = "Enter valid first name";
          }else{
            billingError[e.target.name].status = false;
          }
          break;
        
        case "billingLastName" :
          if(!str.match(/^[a-zA-Z_ ]*$/)){
            billingError[e.target.name].status = true;
            billingError[e.target.name].message = "Enter valid last name";
          }else{
            billingError[e.target.name].status = false;
          }
          break;
          
        case "billingCity" :
          if(!str.match(/^[a-zA-Z_ ]*$/)){
            billingError[e.target.name].status = true;
            billingError[e.target.name].message = "Enter valid city";
          }else{
            billingError[e.target.name].status = false;
          }
          break;
          
        case "billingZip" :
          if(!this.validateZipCode(this.state.billingCountry, str)){
            billingError[e.target.name].status = true;
            billingError[e.target.name].message = "Enter valid ZIP code ";
          }else{
            billingError[e.target.name].status = false;
          }
          break;
        
        case "phone" :
          if (!str.match(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/)) {
          // if((str.length > 10 || str.length < 10) ){
            billingError[e.target.name].status = true;
            billingError[e.target.name].message = "Enter valid phone number";
          }else{
            billingError[e.target.name].status = false;
          }
          break; 
          case "billingAddress1" :
            if (str === "") {
              billingError[e.target.name].status = true;
              billingError[e.target.name].message = "Enter valid address";
            }else{
              billingError[e.target.name].status = false;
            }
            break;
        case "gift_email":
          if(this.validateEmail(str)!==true){
            giftError['gift_email'].status = true;
            giftError['gift_email'].message = "Enter valid gift email";
          }else{
            giftError['gift_email'].status = false;
            giftError['gift_email'].message = "";
          }
        break;
        case "gift_message":
          if(str===""){
            giftError['gift_message'].status = true;
            giftError['gift_message'].message = "Enter valid gift message";
          }else{
            giftError['gift_message'].status = false;
            giftError['gift_message'].message = "";
          }
        break;
        
        default :
          break;
      }  

    //init the 3DS
    let verifyArray=['cc_number','cc_exp_date'];
    if(verifyArray.indexOf(e.target.name) >-1 && "verify3DsListener" in window && this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_enable_3d_verify && this.state.threeDsLoaded === true){
      window["verify3DsListener"]();
    }

  }

  async handleCountryChange(country) {
     let list = await Places.getStatesviaProps(this.props.storeCountries.countries, country);
      this.setState({
       stateData: list.length > 0 ? list : this.state.emptyStateArr,
       showState: list.length > 0 ? true : false,
       selectedCountry: country,
     });

     let defaultSelectedState = (Object.keys(this.state.stateData).length > 1) ? Object.values(this.state.stateData)[0] : false  
    if(defaultSelectedState){
       this.setState( { billingState : (this.state.billingState === "") ? defaultSelectedState.code : this.state.billingState } )
     }
     this.setState({billingCountry: country, billingState: defaultSelectedState.code});
  }

  async handleChange(e) {
   
    if( typeof e !== 'string'){
    await this.onChange(e);
    let list = await Places.getStatesviaProps(this.props.storeCountries.countries, this.state.billingCountry);
    
    this.setState({
      stateData: list && list.length > 0 ? list : this.state.emptyStateArr
    });
    let defaultSelectedState = (Object.keys(this.state.stateData).length > 1) ? Object.values(this.state.stateData)[0] : false  
    if(defaultSelectedState){
      this.setState( { billingState : (this.state.billingState === "") ? defaultSelectedState.code : this.state.billingState } )
    }
  }
  }

  async handleDateExp(e){
    // Mask card-date
    e.target.value = e.target.value.replace(/^(\d{2})(\d{1}).*/, '$1/$2');
    this.handleChange(e);
  }

  addSlash(e) {
    e.preventDefault();
    let str = e.target.value;
    this.setState({ expiryDate: str });
    let newStr = "";
    let len = str.length;
    for (let i = 0; i < len; i++) {
      newStr = newStr + str[i];
      while (newStr.length % 2 === 0) {
        newStr = newStr + "/";
      }
    }
  }


  async validateProspect(){
    try{
      
        let error = "";
        if(this.state.initialValues && !this.state.initialValues.shipping && (this.props.llConfig.cartData.token_based_user_login && !this.props.llConfig.cartData.token_based_user_free_shipping)){
          error ="Select a shipping method to continue with payment.";
        }

        if(error!==""){
          swal(error,{
            icon:"error",
          });
          this.scrollShippingPageDiv.current.scrollIntoView({ behavior: 'smooth' })
          return false;
        }

        if(this.state.show_gift_form && this.state.show_gift_form==="yes"){

          if(this.validateEmail(this.state.gift_email)!==true){
            swal("Enter valid gift email",{
              icon:"error",
            });

            return false;
          }

          if(this.state.gift_message===""){
            swal("Enter valid gift message",{
              icon:"error",
            });

            return false;
          }
        }
        
        let checkout_enable_tandc = this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_enable_tandc ?this.props.llConfig.cartData.checkout_enable_tandc: false;
        let tandc_iagree_detail = this.props.llConfig.cartData && this.props.llConfig.cartData.tandc_iagree_detail ?this.props.llConfig.cartData.tandc_iagree_detail: "terms and conditions";

        let tandc_status = ls.get("tandc_status");
        tandc_status = tandc_status ? tandc_status:false;

        if(checkout_enable_tandc && tandc_status!==true){
          tandc_iagree_detail = tandc_iagree_detail.replace("{","");
          tandc_iagree_detail = tandc_iagree_detail.replace("}","");

          swal("Please read and confirm '"+tandc_iagree_detail+"'",{
            icon:"error",
          });
          return false;
        }

        
        return true;
    }catch(e){
      return false;
    }
  }

  async processPayPal(e){

    try{
      e.preventDefault();
      if(this.props && this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_card_type_accepted && this.props.llConfig.cartData.checkout_card_type_accepted.find(ele=>(ele==="PayPal")) !== "PayPal"){
        return false;
      }

      let proceedToPaymentStat = Checkout.checkProcessForPaymentorNot();
      if(!proceedToPaymentStat){
        swal("Your cart is empty!", {
          icon: "error",
        });
        return false;
      }

      if(await this.validateProspect()!==true){
        return false;
      }

        let prospectData = await Checkout.getProspect();
        
        if(!prospectData){
          return 0;
        }
        
        this.togglePaypal();
        let param ={
          billingSameAsShipping:"yes",
          firstName:prospectData.firstName,
          lastName:prospectData.lastName,
          shippingAddress1:prospectData.address1,
          address1:prospectData.address1,
          shippingAddress2:prospectData.address2,
          shippingCity:prospectData.city,
          city:prospectData.city,
          shippingState:prospectData.state,
          state:prospectData.state,
          shippingZip:prospectData.zip,
          zip:prospectData.zip,
          shippingCountry:prospectData.country,
          country:prospectData.country,
          phone:prospectData.phone,
          email:prospectData.email,
          creditCardType:"paypal",
          tranType:"sale",
          shippingId:(prospectData && prospectData.shipping) ? prospectData.shipping : ""
        }

        let values = await this.getFormParam();

        if(values && 'gift' in values){
          param.gift = values.gift;
        }

        await googleInstance.beginProcess('add_payment_info');

        //sessionID checkout_enable_kount

        if("getMercSessId" in window && this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_enable_kount && this.state.kountLoaded === true){
          param.sessionID = window["getMercSessId"]();
        }
         
        let cartInfo = await Checkout.getCartDataAtLocal();
        
        let callResponse =await Checkout.processPayPalOrder(param);
        let response = await payPal.processPaypal(callResponse);
        
        if(response){
          await googleInstance.beginProcess('checkout_progress');
          ls.set('paymentType', "Paypal")
          let paypalResponse =  await Checkout.getPaypalResponse();
          callResponse.orderId = await Checkout.getOrderConfirmId();
          callResponse.tax = (paypalResponse &&  paypalResponse.orderSalesTaxAmount) ? paypalResponse.orderSalesTaxAmount : "0.00";
          callResponse.products = (cartInfo && cartInfo.cartInfo) ? cartInfo.cartInfo.shopDetails.products : {};
          await Checkout.processOrderForShopify(callResponse);
          await this.checkoutNextProcess({});
        }
      
    }catch(e){
      console.log("Error: ",e);
      
    }
    this.togglePaypal();

  }


  //Amazon pay

  async handleAmazonPaySubmit(stateRef){
    try{
      
      if(this.props && this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_card_type_accepted && this.props.llConfig.cartData.checkout_card_type_accepted.find(ele=>(ele==="AmazonPay")) !== "AmazonPay"){
        swal("Amazon pay agrement has been expired, Please try again",{
          icon:"error",
        });
        return false;
      }
      let amazonAccessToken = stateRef && stateRef.amazonAccessToken ? stateRef.amazonAccessToken : '';
      let amazonBillingAgreementId = stateRef && stateRef.amazonBillingAgreementId ? stateRef.amazonBillingAgreementId : '';
      let amazonOrderReferenceId  = stateRef && stateRef.amazonOrderReferenceId ? stateRef.amazonOrderReferenceId : '';

      if(amazonAccessToken==='' || amazonBillingAgreementId==='' || amazonOrderReferenceId===''){
        swal("Amazon pay agrement has been expired, Please try again",{
          icon:"error",
        });
        return 0;
      }
      let prospectData = await Checkout.getProspect();
      await googleInstance.beginProcess('add_payment_info');
      if(!prospectData){
        swal("Invaild prospect detail",{
          icon:"error",
        });
        return 0;
      }
      

      swal("Order is processing.....", {
        icon: "warning",
        buttons:false
      });

      let values =await this.getFormParam();
      values.access_token = amazonAccessToken;
      values.order_reference_id = amazonOrderReferenceId;
      values.billing_agreement_id = amazonBillingAgreementId

      values.billingZip = values.zip;
      values.endPoint = 'process-onepage-checkout';
      values.creditCardType = 'amazonpay';
      values.billingSameAsShipping = this.state.sameAsBilling;
      
      values.shippingAddress1= values.address1;
      values.shippingAddress2=values.address2;
      values.shippingCity = values.city;
      values.shippingState = values.state;
      values.shippingZip = values.zip;
      values.shippingCountry = values.country;
      values.phone = values.phone ? values.phone:"" ;
      values.email=values.email? values.email :"";
      values.tranType="sale";
      values.payment_page_custom_fields = this.state.customfields;
      
      if(values.billingSameAsShipping === "NO"){
        let billingaddress = {
          billingFirstName: this.state.billingFirstName,
          billingLastName: this.state.billingLastName,
          billingAddress1: this.state.billingAddress1,
          billingAddress2: this.state.billingAddress2,
          billingCity: this.state.billingCity,
          billingState: this.state.billingState,
          billingZip: this.state.billingZip,
          billingCountry: this.state.billingCountry,
          phone: this.state.phone,
        }
        ls.set("billingAddress", billingaddress)
      }

      let processRes = await Checkout.processOrder(values);
      if(processRes){
        ls.set('paymentType', "Amazon Pay")
        await this.checkoutNextProcess(values);
        swal.close();
        await googleInstance.beginProcess('checkout_progress');
      }else{
        swal.close();
        swal("Something went wrong while processing your order, please try again later.",{
          icon:"error",
        });
      }
      
    }catch(e){
      //
      console.log("Error :",e);
    }
  }

  onGetCardNonce(e){
    try{
      e.preventDefault();
     console.log("it is working");
    }catch(e){
      console.log("Error: While processing the square",e);
    }
  }

  async initAmazonPay(e){
    try{

      if(await this.validateProspect()!==true){
        return false;
      }

      let proceedToPaymentStat = Checkout.checkProcessForPaymentorNot();
      if(!proceedToPaymentStat){
        swal("Your cart is empty!", {
          icon: "error",
        });
        return false;
      }

      //remove the previous amazonpay token
      ls.remove("amazonPay");
      
      var self=this;
      var milliseconds = 200;
      if(document.getElementsByClassName("amazonpay-button-inner-image").length>0){
        document.getElementsByClassName("amazonpay-button-inner-image")[0].click();
        let intervalVal = setInterval(function(){
            let amazonPayStatus = ls.get("amazonPay");
            if(amazonPayStatus && amazonPayStatus.amazonAccessToken){
              self.toggleAmazon();
              self.openAmazonPaywidget();
              clearInterval(intervalVal);
            }
            milliseconds = milliseconds +200;
            
            //clear the interval if user has been idel last 2 mnt 
            if(milliseconds>120000){
              self.toggleAmazon();
              clearInterval(intervalVal);
            }
          }, 200);
      }

    }catch(e){
      console.log("Error",e);
    }
  }

  validateZipCode(country, zip){
    try{
      let postCodeValidation = false;
      zip = Checkout.validateSpaceForInput(zip, true);
      if(country && zip && postcodeValidatorExistsForCountry(country)){
        postCodeValidation = postcodeValidator(zip,country);
      }

      if(!postCodeValidation && country && zip &&  !postcodeValidatorExistsForCountry(country)){
        postCodeValidation= true;
      }

      return postCodeValidation;
    }catch(e){
      console.log("Error",e);
      return false;
    }
  }

  async openAmazonPaywidget(){
    try{
      let paymentStatus = await payPal.processPrivateWindowForAmazon();
      let amazonPay = ls.get("amazonPay");
      
      if(paymentStatus && amazonPay &&  amazonPay.processPayment){
        this.handleAmazonPaySubmit(amazonPay)
        this.toggleAmazon();
      }else{
        this.toggleAmazon();
      }
      
    }catch(e){
      console.log("Amazon pay error:",e);
    }
  }

  async squareCallBackForRenderForm(nonce){
    try{
        if(!nonce){
          return false;
        }
        
        await this.squareProcessPayment(nonce);
        
        ls.set("informSquareLadaButtonStatus",true);
    }catch(e){
      console.log(e, "");
    }
  }

async handleFocus(e){
  var contidionalFields = document.getElementsByClassName("card-conditional-field");
  let conditionalCardValidation = this.state.conditionalCardValidation;
  conditionalCardValidation = [];
  if(Object.keys(contidionalFields).length > 0){
    Object.keys(contidionalFields).forEach((key)=>{
      if(e.target.name !== contidionalFields[key].name){
        conditionalCardValidation.push(contidionalFields[key].name);
      }  
    });
  }
  this.setState({conditionalCardValidation : conditionalCardValidation});
}  


async handleCustomFieldChange(e) {
  if(e.target.name.includes("customfields_")){
    let cust_f = e.target.name.split("_");
    let cust_f_k = cust_f[1];
    let customfields = this.state.customfields ? this.state.customfields : {};
    // if value of current element is input_check then use its checked property
    customfields[cust_f_k.toString()] = e.target.value === 'input_check' ? e.target.checked : e.target.value;
    this.setState({customfields});
  }
}
createInputs(position) {
  let custom_fields = this.props.llConfig.cartData.checkout_custom_fields && this.props.llConfig.cartData.checkout_enable_custom_fields ? this.props.llConfig.cartData.checkout_custom_fields : [];
  return custom_fields.map((el, i) =>
    el.placement === position ? 
    <><div className="contact-information billing-content"> 
      <h4>{el.name}</h4><p className='title-text'>{el.field_hint}</p>
      {el.field_type_id.toString() === "1" ?
      <input name={`customfields_`+el.sticky_field_id+`-`+el.type_id+`-`+el.field_type_id+``} type="text" onChange={e=>this.handleCustomFieldChange(e)} placeholder={el.field_placeholder !=='' ? el.field_placeholder : el.name} className="form-control"/>
        :'' }
      {el.field_type_id.toString() === "2" ?
      <input name={`customfields_`+el.sticky_field_id+`-`+el.type_id+`-`+el.field_type_id+``} type="number" onChange={e=>this.handleCustomFieldChange(e)} placeholder={el.field_placeholder !=='' ? el.field_placeholder : el.name} className="form-control"/> 
        :'' }
      {el.field_type_id.toString() === "3" ?
      <input name={`customfields_`+el.sticky_field_id+`-`+el.type_id+`-`+el.field_type_id+``} type="date" onChange={e=>this.handleCustomFieldChange(e)} placeholder={el.field_placeholder !=='' ? el.field_placeholder : el.name} className="form-control" /> 
        :'' }
      {el.field_type_id.toString() === "4" ? 
        <div className="contact-form billing-form">
          <div className={`custom-control custom-radio  ${this.props.checkout_enable_single_page ? 'op-border-black' :'notonepage'}`}>
            <input name={`customfields_`+el.sticky_field_id+`-`+el.type_id+`-`+el.field_type_id+``} value='input_check' type="checkbox"  onChange={e=>this.handleCustomFieldChange(e)} className="form-check-input" id={`styled-checkbox-${el.sticky_field_id}`}/>
            <label className="form-check-label" htmlFor={`styled-checkbox-${el.sticky_field_id}`} >{el.name}</label>
            </div>
          </div>
        :'' }
      {el.field_type_id.toString() === "5" ?
      <div className="selectbox-control-cstm">
        <select name={`customfields_`+el.sticky_field_id+`-`+el.type_id+`-`+el.field_type_id+``} placeholder={el.name} onChange={e => {this.handleCustomFieldChange(e)}} className="selectpicker form-control"> 
        <option disabled selected>{el.field_placeholder !=='' ? el.field_placeholder : `${el.name}`}</option>
          {          
            el.options.map(opel => {
              return <option value={opel.id}>{opel.value}</option>
          })
          }
        </select>
        <div className="field__caret"><i className="fa fa-caret-down"></i></div>
      </div> 
        :'' }
    </div></> : ''
  );
}

render() {
    return (
      <React.Fragment>
      
      {(this.props.llConfig.cartData && !this.props.llConfig.cartData.disable_submit) ? 
          
      <Formik initialValues={initialValues} validationSchema={validationSchema}
      onSubmit={(values, actions) =>
        this.handleSubmit(this.props, values, actions)
      }
       validate={() =>
           this.requiredCardData()
        }
       
      >
        {props => (
          <div>
            
            <Form  id="billing-form">
              {/*-------home page contact information---------*/}
              <div className="contact-information">
                <div className="row">
                  <div className="col-md-12">
                    <div className={`change-contact widht99 ${this.props.checkout_enable_single_page ? 'op-border-black' :'notonepage'}`}>
                      {/*-------change email section---------*/}
                      <div className="bottom-border-div">
                        <div className="row">
                          <div className="col-md-2 ">
                            <p className="light-color">Contact</p>
                          </div>
                          <div className="col-md-8 col-sm-10 col-9">
                            <p>{this.state.initialValues.email}</p>
                          </div>
                          <div className="col-md-2 col-sm-2 col-3">
                            {this.props.checkout_enable_single_page ?
                            <div className="paymentpage-div" onClick={ e => this.props.handleAccordianOnClick('customer-info')}>Change</div>
                            :
                            <Link to={`/app/${this.props.llConfig.llHash}`}>Change</Link>}
                          </div>
                        </div>
                      </div>
                      {/*-------End change email section---------*/}
                      {/*-------change address section---------*/}
                      <div className="bottom-border-div">
                        <div className="row">
                          <div className="col-md-2">
                            <p className="light-color">Ship to</p>
                          </div>
                          <div className="col-md-8 col-sm-10 col-9">
                            <p>
                              {(this.state.initialValues.address1 ? this.state.initialValues.address1 :'') +
                                ", " +
                                (this.state.initialValues.zip ? this.state.initialValues.zip :'') +
                                " " +
                                (this.state.initialValues.city ? this.state.initialValues.city : '') +
                                " " +
                                (this.state.initialValues.state ? this.state.initialValues.state:'') +
                                ", " +
                                this.state.initialValues.country}
                            </p>
                          </div>
                          <div className="col-md-2 col-sm-2 col-3">
                          {this.props.checkout_enable_single_page ?
                            <div className="paymentpage-div"  onClick={ e => this.props.handleAccordianOnClick('customer-info')}>Change</div>
                            :
                            <Link to={`/app/${this.props.llConfig.llHash}`}>Change</Link>}
                          </div>
                        </div>
                      </div>
                      {/*-------End change address section---------*/}
                    </div>
                  </div>
                </div>
              </div>
              {/*-------End home page contact information---------*/}
              {/* -----Shipping info start------- */}
              <ShippingPage
                cart={this.state.cart.cartInfo}
                updateShipping={this.updateShippingM}
                prospectData={this.state.initialValues}
                updateTax={this.props.updateTax}
                scrollShippingPageDiv={this.scrollShippingPageDiv}
                countryData={this.props.storeCountries.countries}
              ></ShippingPage>
              {/* -----Shipping info end------- */}
              
              <GiftForm 
                      formState={{
                                  gift_email:this.state.gift_email,
                                  gift_message:this.state.gift_message,
                                  show_gift_form:this.state.show_gift_form}} 
                                  onChangeEvent={(e)=>this.onChange(e)}
                                  giftError={this.state.giftError}
                                  requiredCardData={this.requiredCardData}
                      cartInfo={this.state.cart.cartInfo}
              />


              {/* -----Checkout gift products start------- */}
              <CheckoutGiftPage 
                cartInfo={this.state.cart.cartInfo}
                updateGiftItem={this.props.updateGiftItem}
              />
              {/* -----Checkout gift products end------- */}
              {/* -----One click Upsell products start------- */}  
              <div className="contact-information">
                { this.props.isMobile && <OneClickUpsell cartInfo={this.props.llConfig.cartData}  returnProps={(e)=>this.getUpdateProps(e)}/>}
              </div>
              {/* -----One click Upsell products start------- */}

              {/* Start Custom fields before billing info */}
              {this.createInputs('before-billing-information')}
              {/* End Custom fields before billing info  */}
              {!this.props.showTokenUserPayment &&
              <div className="contact-information billing-content">
                <h3>Billing address &nbsp;{this.props.checkout_enable_single_page ? <span href="#" data-toggle="tooltip" className="tool_tip span-cursor" title="Enter a Billing address, if it's different from Shipping address."><i className="fa fa-question-circle "></i></span> : ''}</h3>
                <p className="title-text">
                  Select the address that matches your card or payment method.
                </p>
              
                <div className="billign-address">
                  <div className="contact-form billing-form">
                      

                    <div className={`custom-control custom-radio  ${this.props.checkout_enable_single_page ? 'op-border-black' :'notonepage'}`}>
                    <input type="checkbox" className="form-check-input" id="styled-checkbox-1" value="NO" onChange={this.billingChange}
                        defaultChecked={this.state.sameAsBilling ==='NO' ? true : false}
                      />
                      <label className="form-check-label" htmlFor="styled-checkbox-1">
                        Use a different billing address
                      </label>
                    </div>

                  </div>

                  {this.state.errSameAsBilling === true ? (
                    <div className="shipping-validation error">
                      Plesae select a valid input
                    </div>
                  ) : (
                    ""
                  )}

                

                  {!this.state.useDiffBilling ? "" : <BillingAddressForm initialValues={this.state.initialValues}  billingCountry={this.state.billingCountry} requiredCardData={this.requiredCardData} billingError={this.state.billingError}  changeActoion={(e)=>this.onChange(e)} billingInfo={this.state} handleContryChange={(e)=>this.handleChange(e)}/>}
                  <div id="billing-div" />
                </div>
              </div>
              }
              {/* Start Custom fields after billing info */}
              {this.createInputs('after-billing-information')}
              {/* End Custom fields after billing info  */}

              {/* Start Custom fields before payment */}
              {this.createInputs('before-payment-information')}
              {/* End Custom fields before payment  */}

              <div className="contact-information">
                <h3>Payment &nbsp;{this.props.checkout_enable_single_page ? <span href="#" data-toggle="tooltip" className="tool_tip span-cursor" title="Select payment method for the order."><i className="fa fa-question-circle "></i></span> : ''}</h3>
                <p className="title-text">
                  All transactions are secure and encrypted.
                </p>
                {
                   this.props.showTokenUserPayment && this.props.token_based_user_payments && this.props.token_based_user_login &&
                   <PaymentMethodsAddresses 
                    token_based_user_payments={this.props.token_based_user_payments}
                    token_based_user_bill_add={this.props.token_based_user_bill_add}       
                    showTokenUserPayment ={this.props.showTokenUserPayment}
                    showTokenUserPaymentMethod={this.props.showTokenUserPaymentMethod}
                    userTokenShowListPaymentpage={this.props.userTokenShowListPaymentpage}
                    enable_single_page={this.props.checkout_enable_single_page} 
                    tokenUserPaymentBillingAddChange={this.tokenUserPaymentBillingAddChange}
                    />
                }
                {(!this.props.showTokenUserPayment || !this.props.token_based_user_payments || !this.props.token_based_user_login) &&
                
                this.state.expressCheckoutEnable && <div className={`paypal-payment-section  ${this.props.checkout_enable_single_page ? 'op-border-black' :'notonepage'}`}> 
                        <div className="express-checkout">
                          <p>Express Checkout</p>
                        </div>    

                        <div className="row">
                            {(this.props && this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_card_type_accepted && this.props.llConfig.cartData.checkout_card_type_accepted.find(ele=>(ele==="PayPal")) === "PayPal" ? true:false) && <div className={this.state.expressCount > 1 ? "col-sm-6 text-center": "col-sm-12 text-center"}>
              
                              <LaddaButton
                                        className="btn paypal-button"
                                        type="button"
                                        loading={this.state.payPalLoading}
                                        data-size={L}
                                        data-style={SLIDE_UP}
                                        data-spinner-size={30}
                                        data-spinner-color="#ddd"
                                        data-spinner-lines={12}
                                        onClick={(e)=>this.processPayPal(e)}
                                        disabled={this.props && this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_card_type_accepted && this.props.llConfig.cartData.checkout_card_type_accepted.find(ele=>(ele==="PayPal")) === "PayPal" ? false: true}
                                      >
                                      <span className="paypal-logo">
                                          <img src={paypallogo} alt="paypallogo"/>
                                      </span>
                                </LaddaButton>
                            
                            </div>
                            }
                            
                            {(this.props && this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_card_type_accepted && this.props.llConfig.cartData.checkout_card_type_accepted.find(ele=>(ele==="Square")) === "Square" ? true: false) && <div className={this.state.expressCount > 1 ? "col-sm-6 text-center": "col-sm-12 text-center"}>
                              {this.state.isOnlySquare === true &&  <div className="row">
                                  <div className="col-sm-12">
                                        <ProcessSquare informrender={true} squareCallBackForRenderForm={(nonce)=>this.squareCallBackForRenderForm(nonce)}/>
                                  </div>
                              </div>}
                              
                                {this.state.isOnlySquare === false && <LaddaButton
                                className="squarebtn btn btn btn-light "
                                type="button"
                                loading={this.state.squareLoading}
                                data-size={L}
                                data-style={SLIDE_UP}
                                data-spinner-size={30}
                                data-spinner-color="#3b7bbf"
                                data-spinner-lines={12}
                                onClick={(e)=>this.handleSquareSubmit(e)}
                                disabled={this.props && this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_card_type_accepted && this.props.llConfig.cartData.checkout_card_type_accepted.find(ele=>(ele==="Square")) === "Square" ? false: true}
                              >
                              <span className="paypal-logo">
                                <img src={squareImg} style={{"height":"52px"}} className="square-logo" alt="squareImg"/>
                              </span>
                              </LaddaButton>}
                            </div>
                          }
                          
                          {(this.props && this.props.llConfig.cartData && this.props.llConfig.cartData.checkout_card_type_accepted && this.props.llConfig.cartData.checkout_card_type_accepted.find(ele=>(ele==="AmazonPay")) === "AmazonPay" ? true: false) && <div className={this.state.expressCount >1 ? (this.state.expressCount >2 ? "col-sm-6 text-center mt-3" : "col-sm-6 text-center" ) : "col-sm-12 text-center"}>
                              <LaddaButton
                                        className="btn amazon-button"
                                        type="button"
                                        loading={this.state.amazonLoading}
                                        data-size={L}
                                        data-style={SLIDE_UP}
                                        data-spinner-size={30}
                                        data-spinner-color="#F79C34"
                                        data-spinner-lines={12}
                                        onClick={(e)=>this.initAmazonPay(e)}
                                      >
                                      <span className="amazon-logo">
                                          <img src={amazonPayLogo} alt="amazonPayLogo"/>
                                      </span>
                                </LaddaButton>
                            <AmazonPayProcess handleAmazonPaySubmit={(response)=>this.handleAmazonPaySubmit(response)} {...this.props} openPaymentOption={false}/>
                          </div>}
                            
                          
                        </div>
                        
              </div>
              }
              
                {!this.props.showTokenUserPayment && this.state.enableCCForm && <div className="card" ref={this.scrollCardFormDiv}>
                { this.props.checkout_enable_single_page ? 
                 <h3>Credit card</h3> :
                  <div className="card-header">
                    <h6>Credit card</h6>
                  </div>
                }
                  <div className={`${this.props.checkout_enable_single_page ? '' : 'card-detail'}`}>
                    <div className={`${this.props.checkout_enable_single_page ? 'row' : 'contact-frm form-row'}`}>
                      <div className="form-group col-md-12">
                        <Field
                          name="cc_number"
                          type="text"
                          placeholder="Card number"
                          className={
                            "form-control card-conditional-field" +
                            (this.state.cardErr.cc_number.status === true
                              ? " is-invalid"
                              : "")
                          }
                          maxLength="16"
                          value={this.state.cc_number}
                          onChange={e => this.onChange(e)}
                          data-threeds="pan"
                          onFocus={e => this.handleFocus(e)}
                        />
                        {this.state.cardErr.cc_number.status === true ? (
                          <div className="shipping-validation error">
                            {this.state.cardErr.cc_number.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group col-md-12">
                        <Field
                          type="text"
                          name="cc_holder_name"
                          className={
                            "form-control card-conditional-field" +
                            (this.state.cardErr.cc_holder_name.status === true
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Name on card"
                          value={this.state.cc_holder_name}
                          onChange={e => this.onChange(e)}
                          onFocus={e => this.handleFocus(e)}
                        />
                        {this.state.cardErr.cc_holder_name.status === true ? (
                          <div className="shipping-validation error">
                            {this.state.cardErr.cc_holder_name.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <Field
                          type="text"
                          name="cc_exp_date"
                          onChange={e => this.handleDateExp(e)}
                          value={this.state.cc_exp_date}
                          className={
                            "form-control card-conditional-field" +
                            (this.state.cardErr.cc_exp_date.status === true
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Expiration date (MM/YY)"
                          onFocus={e => this.handleFocus(e)}
                        />
                        {this.state.cardErr.cc_exp_date.status === true ? (
                          <div className="shipping-validation error">
                            {this.state.cardErr.cc_exp_date.message}
                          </div>
                        ) : (
                          ""
                        )}


                        <Field
                          type="hidden"
                          name="x_exp_month"
                          data-threeds="month"
                          value={this.state.cc_exp_date && this.state.cc_exp_date.split('/') && this.state.cc_exp_date.split('/').length >1 && this.state.cc_exp_date.split('/')[0] ? this.state.cc_exp_date.split('/')[0]:""}
                        />
                        <Field
                          type="hidden"
                          name="x_exp_month"
                          data-threeds="year"
                          value={this.state.cc_exp_date && this.state.cc_exp_date.split('/') && this.state.cc_exp_date.split('/').length >1 && this.state.cc_exp_date.split('/')[1] ? this.state.cc_exp_date.split('/')[1]:""}
                        />
                        <Field
                          type="hidden"
                          name="x_amount"
                          data-threeds="amount"
                          value={this.props.llConfig.cartData && this.props.llConfig.cartData.cartSubTotal ? this.props.llConfig.cartData.cartSubTotal : 0}
                        />

                        <Field
                          type="hidden"
                          name="x_transaction_id"
                          value={this.state.x_transaction_id}
                          data-threeds="id"
                        />  

                      </div>
                      <div className="form-group col-md-6">
                        <Field
                          type="text"
                          name="cc_cvv"
                          className={
                            "form-control card-conditional-field" +
                            (this.state.cardErr.cc_cvv.status === true
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Security code"
                          value={this.state.cc_cvv}
                          onChange={e => this.onChange(e)}
                          onFocus={e => this.handleFocus(e)}
                        />
                        {this.state.cardErr.cc_cvv.status === true ? (
                          <div className="shipping-validation error">
                            {this.state.cardErr.cc_cvv.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
              
              {/*-------End home page Shipping Address---------*/}
              {/* Start Custom fields after payment */}
              {this.createInputs('after-payment-information')}
              {/* End Custom fields after payment  */}
            
              {/* TandC div */}
              <div className="contact-information tandc-content">
                  <TandC cartInfo={this.props.llConfig.cartData}/>
              </div>
              <div className="contact-information pay-now-content">
              <div className="pay-div">
                    <div className="row">
                      <div className="col-md-12">

                        {this.state.enableCCForm  && <LaddaButton
                          className="bg-blue"
                          type="submit"
                          loading={this.state.loading}
                          data-size={L}
                          data-style={SLIDE_UP}
                          data-spinner-size={30}
                          data-spinner-color="#ddd"
                          data-spinner-lines={12}
                        >
                          Pay now
                        </LaddaButton>}

                        { this.props.token_based_user_login && !this.props.showTokenUserPayment && this.props.token_based_user_payments && Object.keys(this.props.token_based_user_payments).length > 0 ?
                        <span onClick={ e => this.tokenUserPaymentMethod()} className="return_btn span-cursor">
                        <i className="fa fa-angle-left" style= {{"color": "#007bff"}}/>  Return to payment list
                        </span> :  this.props.checkout_enable_single_page ?
                        <Link to={`/app/${ls.get('ll_hash')}`} onClick={ e => this.props.handleAccordianOnClick('customer-info')} className="return_btn">
                        <i className="fa fa-angle-left" /> Return To Customer Information
                        </Link>
                        :
                        <Link to={`/app/${this.props.llConfig.llHash}`}><i className="fa fa-angle-left" /> Return To Customer Information</Link>
                        }
                      </div>
                    </div>
                  </div>
              </div>
            
            </Form>
          </div>
        )}
      </Formik> : <LeftSkeleton />}
    
      </React.Fragment>

    
    );
  }
}

const ConnectedPaymentPage = connect(
  mapStateToProps,
  null
)(PaymentPage);

export default withUnmounted(ConnectedPaymentPage);

const initialValues = Checkout.getProspect();

const validationSchema = Yup.object().shape({
});

class BillingAddressForm extends Component {
 
  render() {
    
    return (
      <div className={`billing-add-form-style ${this.props.checkout_enable_single_page ? 'op-border' : ''}`} id="billing-address-form">
        <div className="contact-frm form-row">
      
          <div className="form-group col-lg-6">
            <input
              type="text"
              placeholder="First Name"
              name="billingFirstName"
              onChange={e=>this.props.changeActoion(e)}
              className={this.props.billingError.billingFirstName.status===true ? 'form-control is-invalid':'form-control'}
            />
            {this.props.billingError.billingFirstName.status === true ? (
                          <div className="shipping-validation error">
                            {this.props.billingError.billingFirstName.message}
                          </div>
                        ) : (
                          ""
                        )}

          </div>
          <div className="form-group col-lg-6">
            <input
              type="text"
              placeholder="Last Name"
              name="billingLastName"
              onChange={e=>this.props.changeActoion(e)}
              className={this.props.billingError.billingLastName.status===true ? 'form-control is-invalid':'form-control'}
              />
              {this.props.billingError.billingLastName.status === true ? (
                            <div className="shipping-validation error">
                              {this.props.billingError.billingLastName.message}
                            </div>
                          ) : (
                            ""
                          )}
          </div>
          <div className="form-group col-lg-12">
            <input 
                  type="text"  
                  placeholder="Address"  
                  name="billingAddress1"  
                  onChange={e=>this.props.changeActoion(e)}
                  className={this.props.billingError.billingAddress1.status===true ? 'form-control is-invalid':'form-control'}
                  />
                  {this.props.billingError.billingAddress1.status === true ? (
                                <div className="shipping-validation error">
                                  {this.props.billingError.billingAddress1.message}
                                </div>
                              ) : (
                                ""
                              )}
          </div>
          <div className="form-group col-lg-12">
            <input
              type="text"
              placeholder="Apartment, suite, etc. (optional)"
              name="billingAddress2"
              onChange={e=>this.props.changeActoion(e)}

              className="form-control"
                  />
          </div>
          <div className="form-group col-lg-12">
            <input type="text" 
            placeholder="City" 
            name="billingCity"  
            onChange={e=>this.props.changeActoion(e)}
            className={this.props.billingError.billingCity.status===true ? 'form-control is-invalid':'form-control'}
                  />
                  {this.props.billingError.billingCity.status === true ? (
                                <div className="shipping-validation error">
                                  {this.props.billingError.billingCity.message}
                                </div>
                              ) : (
                                ""
                              )}
          </div>
          <div className="form-group col-lg-4 select-div">
            
            <div className="selectbox-control">
            <select 
              className={this.props.billingError.billingCountry.status===true ? 'selectpicker form-control is-invalid':'selectpicker form-control'}
              name="billingCountry" 
              value={this.props.billingInfo.billingCountry ? this.props.billingInfo.billingCountry : this.props.initialValues.country}  
              
              onChange={e => {
                this.props.handleContryChange(e);
              }}
              >
              <option value="">Select Country</option>
              {this.props.billingInfo.countryData.length > 0 &&
                this.props.billingInfo.countryData.map((item, i) => {
                    return (
                      <option key={i} value={item?.code2}>
                        {item?.name}
                      </option>
                    );
                                
                }, this)}
            </select>
            <div className="field__caret">
              <i className="fa fa-caret-down" />
            </div>
            </div>
            

            {this.props.billingError.billingCountry.status === true ? (
                                <div className="shipping-validation error">
                                  {this.props.billingError.billingCountry.message}
                                </div>
                              ) : (
                                ""
                              )}

          </div>
          <div className="form-group col-lg-4 select-div">
          <div className="selectbox-control">
          <select 
                  className={this.props.billingError.billingState.status===true ? 'selectpicker form-control is-invalid':'selectpicker form-control'}
                  name="billingState" 
                  value={this.props.billingInfo.billingState}  
                  onChange={e=>this.props.changeActoion(e)}>
            {this.props.billingInfo.stateData.length > 0 &&
                this.props.billingInfo.stateData.map((item, i) => {
                  return (
                    <option key={i} value={item.code}>
                      {item.name}
                    </option>
                  );
                }, this)}
            </select>
            <div className="field__caret">
              <i className="fa fa-caret-down" />
            </div>
          </div>
            

            {this.props.billingError.billingState.status === true ? (
                                <div className="shipping-validation error">
                                  {this.props.billingError.billingState.message}
                                </div>
                              ) : (
                                ""
                              )}

          </div>
          <div className="form-group col-lg-4">
            <input 
                  type="text" 
                  className={this.props.billingError.billingZip.status===true ? 'form-control is-invalid':'form-control'}
                  placeholder="Zipcode" 
                  name="billingZip"  
                  onChange={e=>this.props.changeActoion(e)}
                  />

                  {this.props.billingError.billingZip.status === true ? (
                                <div className="shipping-validation error">
                                  {this.props.billingError.billingZip.message}
                                </div>
                              ) : (
                                ""
                              )}

          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <input 
                  type="text" 
                  className={this.props.billingError.phone.status===true ? 'form-control is-invalid':'form-control'}
                  placeholder="Phone" 
                  name="phone"
                  maxLength="10"  
                  onChange={e=>this.props.changeActoion(e)}
                 
                  />
                  {this.props.billingError.phone.status === true ? (
                                <div className="shipping-validation error">
                                  {this.props.billingError.phone.message}
                                </div>
                              ) : (
                                ""
                              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
