import React, { Component } from "react";
import {connect} from 'react-redux';
import ls from "local-storage";
import withUnmounted from '@ishawnwang/withunmounted'
import { Link } from "react-router-dom";
import Checkout from "../classes/checkout";
ls.backend(sessionStorage);

const mapStateToProps = (state) => {
  return {
      llConfig: state.llConfig
  };
}

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname : ( this.props && this.props.location.pathname) ? this.props.location.pathname : ""
    }
  }

  refreshPage(){
    window.location.reload()
  }

  async componentWillMount(){
    this.props.setPopException(false)
    try{
      if(this.props.cartInfo === false){
        let response = await Checkout.getCartData();
        if(response || (response.cartInfo && (response.cartInfo.checkout_online_status === true))){
          const { history } = this.props
          this.props.setPopException(true)
          history.push(`/app/${this.props.llConfig.llHash}`)
        }
      }
      
    }catch(e){
      console.log("Error",e);
    }
  }

  render(){
      return(
          <React.Fragment>
            <div className="center-container">
            <div className="error">
                  <div className="container-floud">
                      <div className="col-xs-12 ground-color text-center">
                          <div className="container-error-404">
                              <div className="clip"><div className="shadow"><span className="digit thirdDigit">4</span></div></div>
                              <div className="clip"><div className="shadow"><span className="digit secondDigit">0</span></div></div>
                              <div className="clip"><div className="shadow"><span className="digit firstDigit">4</span></div></div>
                              <div className="msg">OH!<span className="triangle"></span></div>
                          </div>
                          <h2 className="h1">Store is unavailable.</h2>
                          { (this.state.pathname !== "/404" ) ? (<Link className="btn btn-primary" to={`/app/${this.props.llConfig.llHash}`} >Check Store Availability</Link>) : (<Link className="btn btn-primary"  to="/404" onClick={ (e) => { this.refreshPage()} } >Check Store Availability</Link>)}
                      </div>
                  </div>
              </div>
            </div>
          </React.Fragment>
      );
  }
}

const ConnectedNotFound = connect(
  mapStateToProps,
  null
)(NotFound);

export default withUnmounted(ConnectedNotFound);