import React from "react";
import loaderSvg from '../images/loader-svg.svg';

const ProspectLoaded = () => {
  	return (
		<div className="center-container svg-loader">
			<img src={loaderSvg} alt="" />
			<p>We are saving your contact and shipping details...</p>
		</div>
	)
}

export default ProspectLoaded;