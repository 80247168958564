import React, { Component } from "react";
import withUnmounted from '@ishawnwang/withunmounted';

class LeftSkeleton extends Component {
  render(){
      return(
          <React.Fragment>
            

                    <div className="ph-item border-0 p-0">
                        <div className="ph-col-4 pl-0 pr-5">
                            <div className="ph-avatar"></div>
                        </div>

                        <div className="pr-0 pl-0">
                            <div className="ph-row">
                                <div className="ph-col-12 big empty"></div>
                                <div className="ph-col-12 big">
                                <div className="ph-picture mb-0"></div>
                                <div className="ph-picture mb-0"></div>
                                <div className="ph-picture mb-0"></div>
                                <div className="ph-picture mb-0"></div>
                                <div className="ph-picture mb-0"></div>
                                <div className="ph-picture mb-0"></div>
                                </div>
                            </div>
                        </div>

                        <div className="ph-col-12">   
                            <div className="row">
                                <div className="ph-col-12 mb-1">     
                                <div className="ph-row row">
                                    <div className="ph-col-8 mb-5 mt-5"></div> 
                                    <div className="ph-col-8 mb-1"></div> 
                                    </div>
                                </div> 
                                <div className="ph-col-6 pr-1">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    </div>
                                </div>
                                <div className="ph-col-6 pl-1">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="ph-col-12 mt-5">     
                                <div className="ph-row row">
                                    <div className="ph-col-8 mb-1"></div> 
                                    </div>
                                </div> 
                                <div className="ph-col-12 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div>
                                <div className="ph-col-12 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    </div>
                                </div> 
                                <div className="ph-col-12 mb-2">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div>
                                <div className="ph-col-4">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div> 
                                <div className="ph-col-4 px-1">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    </div>
                                </div>
                                <div className="ph-col-4">
                                    <div className="ph-row">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    </div>
                                </div>

                                <div className="ph-col-6 empty">
                                </div>
                                <div className="ph-col-6">
                                    <div className="ph-row pl-5">
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div>
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    <div className="ph-col-12 mb-0"></div> 
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
          </React.Fragment>
      );
  }
}
  
export default withUnmounted(LeftSkeleton);