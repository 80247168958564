import React, { Component } from 'react'
import { ReCAPTCHA } from "react-google-recaptcha";
class GoogleRecaptcha extends Component {

  handleverifyCallback = (token) => {
    this.props.verifyCallback(token)
  }
  render() {
    const grecaptchaObject = window.grecaptcha // You must provide access to the google grecaptcha object.

    return (
      <div>
        <ReCAPTCHA
          ref={(r) => this.recaptchaInstance = r}
          sitekey={this.props.sitekey}
          grecaptcha={grecaptchaObject}
          onChange={this.handleverifyCallback}
        />
      </div>
    )
  }
}

export default GoogleRecaptcha;