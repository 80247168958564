import React, { Component } from "react";
import withUnmounted from '@ishawnwang/withunmounted';
import PaymentForm  from './PaymentForm';
import ls from "local-storage";
import {connect} from 'react-redux';

ls.backend(sessionStorage);

const mapStateToProps = (state) => {
  return {
      llConfig: state.llConfig
  };
}

class ProcessSquare extends Component {
  constructor(props) {
    super(props);
    this.state = {squareLoad:false,cartInfo:false, 
                  informrender:this.props.informrender===true ? this.props.informrender : false
                }
    this.closeWindow = this.closeWindow.bind(this);
    this.squareCallBackForRenderForm = this.squareCallBackForRenderForm.bind(this);
    this.getSquareRes = this.getSquareRes.bind(this); 
  }

  getSquareRes(res){
    window.parent.squareResponse = res;
  }

  closeWindow(){
    if(this.state.informrender !== true){
      window.close();
    }
  }

  async componentWillMount() {
    try{

      let llHash = this.props.llConfig.llHash;
      if(!this.props.llConfig.cartData)
      {
        await this.props.initiateConfig(llHash);
      }

      //for Square upload
      let checkout_square_sandbox = this.props.llConfig.cartData  && this.props.llConfig.cartData.checkout_square_sandbox ? this.props.llConfig.cartData.checkout_square_sandbox : false; 
      const that = this;
      let sqPaymentScript = document.createElement('script');
      sqPaymentScript.src = checkout_square_sandbox ? "https://js.squareupsandbox.com/v2/paymentform" : "https://js.squareup.com/v2/paymentform";
      sqPaymentScript.type = "text/javascript"
      sqPaymentScript.async = false;
      document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
      sqPaymentScript.onload = ()=>{that.setState({
        squareLoad: true
      })};


    }catch(e){
      console.log("Error",e);
    }
  }

  squareCallBackForRenderForm(nonce){
    try{
      if(this.state.informrender === true){
          ls.set("informSquareLadaButtonStatus",false);
          this.props.squareCallBackForRenderForm(nonce);
      }
    }catch(e){
      console.log(e);
    }
  }

  render(){

      return(
          <React.Fragment>
            {this.state.squareLoad  ? <PaymentForm getSquareRes={this.getSquareRes} skeletonHtml={this.skeletonHtml} paymentForm={window.SqPaymentForm} cartInfo={this.props.llConfig.cartData} closeWindow={(e)=>this.closeWindow(e)} informrender={this.state.informrender} squareCallBackForRenderForm={(nonce)=>this.squareCallBackForRenderForm(nonce)} informSquareLadaButtonStatus={this.props.informSquareLadaButtonStatus}/> : this.renderSquareSkeleton() }
          </React.Fragment>
      );
  }

  renderSquareSkeleton(){
    return this.state.informrender ? this.skeletonHtml():  <div className="container"> <div className="form-container" style={{ marginTop : "60%" }}> {this.skeletonHtml()}</div> </div>;
  }

  skeletonHtml(){
    return <div className="ph-item border-0 p-0 bg-transparent">
    
    <div className="row"></div>
    <div className="ph-col-3 pr-1"></div>
    <div className="ph-col-6 pr-1">
        <div className="ph-row">
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
        </div>
    </div>
    <div className="ph-col-3 pr-1"></div>
    <div className="row"></div>
    <div className="ph-col-12 pr-1"><hr/></div>
    <div className="row"></div>
    <div className="ph-col-12 pr-1">
        <div className="ph-row">
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
        </div>
    </div>

    <div className="ph-col-12 pr-1">
        <div className="ph-row">
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
        </div>
    </div>

    <div className="ph-col-12 pr-1">
        <div className="ph-row">
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
        </div>
    </div>

    <div className="ph-col-12 pr-1">
        <div className="ph-row">
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
        </div>
    </div>

    <div className="row"></div>
    <div className="ph-col-8 pr-1"></div>
    <div className="ph-col-4 pr-1">
        <div className="ph-row">
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
            <div className="ph-col-12 mb-0"></div>
        </div>
    </div>
</div>;
  }
}

const ConnectedProcessSquare = connect(
  mapStateToProps,
  null
)(ProcessSquare);

export default withUnmounted(ConnectedProcessSquare);