import React, { Component } from "react";
import withUnmounted from '@ishawnwang/withunmounted'
import Checkout from '../classes/checkout';
import ls from "local-storage";
ls.backend(sessionStorage);

class OneClickUpsell extends Component {
    constructor(props) {
        super(props);
        this.addUpsellIncart = this.addUpsellIncart.bind(this);
        this.getSelectedUpsellStatus = this.getSelectedUpsellStatus.bind(this);
        this.createMarkup = this.createMarkup.bind(this);

        this.state = {
            cartInfo: this.props.cartInfo ? this.props.cartInfo : false,
            upsellSelectionStatus:[]
        }  
    }

    componentWillMount(){
        let upsellSelectionStatus = [];
        this.state.cartInfo && 'checkout_one_click_upsell_products' in this.state.cartInfo && this.state.cartInfo.checkout_one_click_upsell_products.length>0 && Object.values(this.state.cartInfo.checkout_one_click_upsell_products).forEach((upsellVal)=>{
            let product_mongodb_id = upsellVal && 'product_mongodb_id' in upsellVal && upsellVal.product_mongodb_id ? upsellVal.product_mongodb_id  :"";
            let selectedStatus = this.getSelectedUpsellStatus(product_mongodb_id);

            if(product_mongodb_id!==""){
                upsellSelectionStatus[product_mongodb_id]=selectedStatus;
            }
            
        })
        this.setState({upsellSelectionStatus:upsellSelectionStatus});
    }

    async addUpsellIncart(e,upsellVal){
        try{
            
            let upsellSelectionStatus = this.state.upsellSelectionStatus;
            let product_mongodb_id = upsellVal && 'product_mongodb_id' in upsellVal && upsellVal.product_mongodb_id ? upsellVal.product_mongodb_id  :"";
            upsellSelectionStatus[product_mongodb_id] = e.target.checked;
            let oneClickUpsells = ls.get("oneClickUpsells");
            oneClickUpsells = oneClickUpsells ? oneClickUpsells : [];
            let elementIndex = -1;

            for(let i=0;i<oneClickUpsells.length;i++){
                if(oneClickUpsells[i] && 'product_mongodb_id' in oneClickUpsells[i] && oneClickUpsells[i]['product_mongodb_id'] === product_mongodb_id){
                    elementIndex = i;
                }
            }
            
            if(e.target.checked){
                //add
                if(elementIndex>-1){
                    oneClickUpsells[elementIndex] = upsellVal;
                }else{
                    oneClickUpsells.push(upsellVal);
                }
            
            }else if(oneClickUpsells[elementIndex]){
                // delete
                oneClickUpsells.splice(elementIndex,1);
            }
           
            ls.set("oneClickUpsells",oneClickUpsells);
            this.setState({upsellSelectionStatus:upsellSelectionStatus});
            let tax = await Checkout.calculateTax();
            this.props.returnProps(tax);
        }catch(e){
            console.log(e);
        }

    }

    getSelectedUpsellStatus(product_mongodb_id){
        try{
            let oneClickUpsells = ls.get("oneClickUpsells");
            oneClickUpsells = oneClickUpsells ? oneClickUpsells : [];
            let selectedStatus = false;
            
            for(let i=0;i<oneClickUpsells.length;i++){
                if(oneClickUpsells[i] && 'product_mongodb_id' in oneClickUpsells[i] && oneClickUpsells[i]['product_mongodb_id'] === product_mongodb_id){
                    selectedStatus = true;
                }
            }
            return selectedStatus;
        }catch(e){
            console.log(e,"status sssss");
            return false;
        }
    }

    getUpsellProductListAction(){
        try{
            
            let products = [];
            let upsellSelectionStatus = this.state.upsellSelectionStatus;
            this.state.cartInfo && 'checkout_one_click_upsell_products' in this.state.cartInfo && this.state.cartInfo.checkout_one_click_upsell_products.length>0 && Object.values(this.state.cartInfo.checkout_one_click_upsell_products).forEach((upsellVal)=>{
            let product_mongodb_id = upsellVal && 'product_mongodb_id' in upsellVal && upsellVal.product_mongodb_id ? upsellVal.product_mongodb_id  :"";
            
                products.push(
                        <div className="order-summery-top scrollbar one-click-style" key={upsellVal.product_mongodb_id}>
                            <div className="row">
                                <div className="col-sm-12 one-click-head-style">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input one-click-radio-change" id={'one_click'+upsellVal.product_mongodb_id} onChange={(e)=>this.addUpsellIncart(e,upsellVal)} checked={upsellSelectionStatus[product_mongodb_id]}/>
                                        <label className="form-check-label" htmlFor={'one_click'+upsellVal.product_mongodb_id}>Add to my order!</label>
                                    </div>
                                </div>

                                <div className="col-sm-12 click-content">
                                <div dangerouslySetInnerHTML={this.createMarkup(upsellVal.description)}></div>
                                
                                </div>
                            </div>
                        </div>
                    )
            },this);
            return products;

        }catch(e){
            console.log(e);
        }
    }

    createMarkup(text) { return {__html: text}; };

    render() {
       

        let upsellProductList = this.getUpsellProductListAction();
        
        return (
            <React.Fragment>
                { window.location.pathname!=='/thank-you' && upsellProductList }
            </React.Fragment>
        )
    }
}


export default withUnmounted(OneClickUpsell);        