import React, { Component } from "react";
import ls from "local-storage";
export default class ShippingAddresses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shippingValue: 0,
        };
        this.addEditShipping = this.addEditShipping.bind(this);
    }
    addEditShipping = (detailsToShow) => {
        if (detailsToShow) {
            this.props.showTokenUserShippingAddresses(false, detailsToShow);
        } else {
            this.props.showTokenUserShippingAddresses(false);
        }
    }
    tokenUserShippingChange = (detail, index) => {
        this.props.showTokenUserShippingAddresses(true, detail);
        this.setState({
            shippingValue: index
        })
        ls.set("shippingAddressID", index)
    }
    componentDidMount() {
        let shippingAddressID = ls.get("shippingAddressID");
        let shippings = this.props.token_based_user_addresses;
        let firstElement = shippings[Object.keys(shippings)[0]]
        if (this.state.shippingValue === 0) {
            this.props.showTokenUserShippingAddresses(true, firstElement);
        }
        if (shippingAddressID) {
            this.setState({
                shippingValue: shippingAddressID
            })
            firstElement = shippings[Object.keys(shippings)[shippingAddressID]];
            this.props.showTokenUserShippingAddresses(true, firstElement);

        }
    }
    render() {
        let shippingAddresses = [];
        let user_shipping_desc_order = this.props.token_based_user_addresses.sort((a, b) => b.id - a.id);
        user_shipping_desc_order && user_shipping_desc_order.forEach((element, index) => {
            shippingAddresses.push((
                <div class="most-recent">
                    <div className={`custom-control custom-radio`} key={element.id}>
                        <input
                            id={element.id}
                            name="shipping"
                            type="radio"
                            className="custom-control-input"
                            value={element}
                            checked={this.state.shippingValue === index}
                            onChange={(e) => this.tokenUserShippingChange(element, index)}
                        />
                        <label className="custom-control-label" htmlFor={element.id}>
                            <span className={"label-position "}>
                                <p class="most-name">{element.first_name} {element.last_name}</p>
                                <address>
                                    {element.street} {element.street_2} {element.city} {element.zip} {element.state} {element.country}
                                </address>
                            </span>
                            <span  onClick={() => this.addEditShipping(element)} className="price-position span-cursor">Edit</span>
                        </label>
                    </div>
                </div>
            ));

        });

        return (
            <div>
                <div className="card" >
                    <div className="card-header">
                        {shippingAddresses.length > 0 && <>
                            <div className={``} >
                                <h4 class="h3-head">Recently used</h4>
                                {shippingAddresses.slice(0, 1)}
                                {shippingAddresses.length > 1 && <>
                                    <h4 class="h3-head">Other Addresses</h4>
                                    {shippingAddresses.slice(1)}
                                </>}
                            </div>
                            <div class="col-md-12 bg-light text-right">
                                <button type="button" class="btn btn-link" onClick={() => this.addEditShipping()}>Add new address</button>
                            </div>
                        </>}
                    </div>

                </div>
            </div>
        )
    }
}
