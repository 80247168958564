import React, { Component } from "react";
import ls from "local-storage";

import master from '../../src/images/mastercard.png';
import visa from '../../src//images/visa.png';
import amex from '../../src/images/amex.png';
import discover from '../../src/images/discover.png';

export default class PaymentMethodsAddresses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userPayment: 0,
        };
    }
    addEditPayment = () => {
        ls.remove('previousOrderId');
        ls.remove('billingAddress');
        this.props.showTokenUserPaymentMethod(false);
        this.props.tokenUserPaymentBillingAddChange("", "YES");
    }

    tokenUserPaymentChange = (orderId, index) => {
        let user_bill_details = this.props.token_based_user_bill_add;
        let billingAddressDetail = user_bill_details[orderId];

        ls.set("previousOrderId", orderId);
        ls.set("paymentAddressID", index);
        let billingaddress = {
            billingFirstName: billingAddressDetail[0],
            billingLastName: billingAddressDetail[1],
            billingAddress1: billingAddressDetail[2],
            billingAddress2: billingAddressDetail[3],
            billingCity: billingAddressDetail[4],
            billingState: billingAddressDetail[6].toUpperCase(),
            billingZip: billingAddressDetail[5],
            billingCountry: billingAddressDetail[7].toUpperCase(),
            phone: billingAddressDetail[8],
        }
        this.props.tokenUserPaymentBillingAddChange(billingAddressDetail, "NO");
        ls.set("billingAddress", billingaddress);

        this.setState({
            userPayment: index
        })
    }

    componentDidMount() {
        let user_payments = Object.keys(this.props.token_based_user_payments).sort((a, b) => b - a)
        let user_bill_details = this.props.token_based_user_bill_add;
        let defaultSelectedBillingAddress = user_bill_details[user_payments[0]];
        let paymentAddressID = ls.get("paymentAddressID");
        // assign first billing address to form by default
        let billingaddress = {
            billingFirstName: defaultSelectedBillingAddress[0] ?? '',
            billingLastName: defaultSelectedBillingAddress[1] ?? '',
            billingAddress1: defaultSelectedBillingAddress[2] ?? '',
            billingAddress2: defaultSelectedBillingAddress[3] ?? '',
            billingCity: defaultSelectedBillingAddress[4] ?? '',
            billingState: defaultSelectedBillingAddress[6].toUpperCase() ?? '',
            billingZip: defaultSelectedBillingAddress[5] ?? '',
            billingCountry: defaultSelectedBillingAddress[7].toUpperCase() ?? '',
            phone: defaultSelectedBillingAddress[8] ?? '',
        }
        this.props.tokenUserPaymentBillingAddChange(defaultSelectedBillingAddress, "NO");
        ls.set("billingAddress", billingaddress);
        if (this.state.userPayment === 0) {
            let firstElement = user_payments[0];
            this.props.showTokenUserPaymentMethod(true, firstElement);
            ls.set("previousOrderId", firstElement);
        }
        if(paymentAddressID){
            this.setState({
                userPayment: paymentAddressID
            }) 
            let firstElement = user_payments[paymentAddressID];
            ls.set("previousOrderId", firstElement);
            this.props.showTokenUserPaymentMethod(true, firstElement);
        }

    }
    render() {
        let paymentMethodsWithAddress = [];
        let user_payments = this.props.token_based_user_payments;
        let user_bill_details = this.props.token_based_user_bill_add;
        let user_payment_desc_order = Object.keys(user_payments).sort((a, b) => b - a)

        user_payment_desc_order.forEach((orderId, index) => {
            let singlePaymentDetails = user_payments[orderId];
            let singleBillDetails = user_bill_details[orderId];
            // check for card image
            let card_image = visa;
            if(singlePaymentDetails[2] === "amex"){
                card_image = amex;
            }else if(singlePaymentDetails[2] === "master"){
                card_image = master;
            }else if(singlePaymentDetails[2] === "discover"){
                card_image = discover;
            }

            paymentMethodsWithAddress.push((<>
                <ul>
                    <li class="credit-raido">

                        <label class="credit-raido-label" htmlFor={index + "payment"}>
                            <input id={index + "payment"}
                                name="payment"
                                type="radio"
                                value={orderId}
                                checked={this.state.userPayment === index}
                                onChange={(e) => this.tokenUserPaymentChange(orderId, index)} />
                            <span class="checkmark"></span>
                        </label>
                    </li>
                    <li class="credit-visha">
                        {<img style={{ "width": "10%" }} src={card_image} alt={singlePaymentDetails[2]} />} <span> ● ● ● {singlePaymentDetails[0]} <span class="exp">Exp. {singlePaymentDetails[1].slice(0, 2)}/20{singlePaymentDetails[1].slice(2, 4)}</span></span>
                    </li>
                    <li class="credit-address">
                        <p class="card-name">{singleBillDetails[0]} {singleBillDetails[1]}</p>
                        <address>
                            <span class="address-name">{singleBillDetails[2]}, {singleBillDetails[3]}</span>
                            &nbsp;<span class="city-name">{singleBillDetails[4]},</span>
                            &nbsp;<span class="zip-code">{singleBillDetails[5]}</span>
                            &nbsp;<span class="state-name">{singleBillDetails[6].toUpperCase()},</span>
                            &nbsp;<span class="country-code">{singleBillDetails[7].toUpperCase()}</span>
                        </address>
                    </li>
                </ul>
            </>))
        });

        return (
            <div className={`  ${this.props.enable_single_page ? 'credit-debit ' : 'multi-page-pay'}`}>
                {paymentMethodsWithAddress}
                <div class="col-md-12 bg-light text-right">
                    <button type="button" class="btn btn-link" onClick={() => this.addEditPayment()}>Add new payment method</button>
                </div>
            </div>
        )
    }
}
